.app {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 55px;
  padding: 40px 40px 210px;
a{
  width: 100%;
}
  .sidebar {
    max-width: 246px;
    width: 100%;
    position: sticky;
    top: 30px;
    h5{
      color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 19.2px */
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
width: 245px;
    }

    .main-logo {
      padding: 20px;
      display: block;
      margin: 0 auto;
    }

    .accordion {

      .accordion-item {
        border-radius: 0;
        border: none;
        background-color: transparent;
        margin-bottom: 20px;

        .accordion-button {
          box-shadow: none;
          background-color: transparent;
          padding: 0px;
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          padding-bottom: 12px;

          &::after {
            background: url("../../assets/arrowdown.svg");
          }
        }

        .accordion-button:not(.collapsed)::after {
          //   background: url("../src/assets/minus-icon.svg");
        }

        .accordion-body {
          padding: 6px 0px;
          border-left: 1px solid #1D1D1D;


          .inneraccordion {
            margin-bottom: 0;
            border-radius: 0;

            .accordion-button {
              color: #FFF;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              padding: 10px 0px 10px 12px;
              border-radius: 0;

              &::after {
                background: url("../../assets/arrowdown.svg");
              }

            }

            .accordion-body {
              padding: 0;

              a {
                padding: 10px 0px 10px 25px;
                border-radius: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 245px;
              }
            }

            .accordion-button:not(.collapsed) {
              background: #0D0D0D;
            }

          }

          a {
            padding: 12px 10px;
          }
        }
      }
    }

    a {
      display: block;
      color: rgba(255, 255, 255, 0.50);
      // &:hover{
      //   color: rgba(255, 255, 255, 0.50);
      // }
    }

    .active {
      background: #0D0D0D;
      color: #FFF;
    }

    .active-link {
      background: #0D0D0D;
      color: #FFF;
    }
  }

  .content {
    flex: 1;
    padding: 0px 0;
    padding-right: 55px;

    .bottombtns {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;

      .btntextlast {
        justify-content: flex-end !important;
        align-items: flex-end !important;
      }

      .innerbtn {
        border: 1px solid #1C1C1C;
        background: #000;
        display: flex;
        padding: 20px 40px 20px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 7px;
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        width: 100%;

        .greentext {
          color: #2DD98F;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .feraturesmain {
      .featuresinnerhead {
        background: #151515;
        display: flex;
        align-items: center;
        padding: 22px 30px;

        .featurehead {
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }

        .heading {
          width: 27%;
        }

        .para {
          width: 73%;
        }
      }

      .dark {
        background: #0C0C0C !important;
      }

      .featuresinner {
        background: #0F0F0F;
        display: flex;
        align-items: center;
        padding: 22px 30px;

        .heading {
          width: 27%;
        }

        .para {
          width: 73%;
        }

        .featurespara {
          color: #C0C0C0;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }


    img {
      display: block;
      margin: 0 auto;
      padding: 25px 0;
    }

    a {
      color: #2DD98F;
    }

    h2 {
      color: #2DD98F;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 20px;
    }
    .update_text{
      margin-top: 40px;
    }
    .code-style {
       /* Dark background */
      color: #00ff84; /* Code-like green text */
      font-family: 'Courier New', Courier, monospace; /* Monospace font */
      font-size: 14px; /* Adjust font size */
      padding: 10px; /* Add some padding */
      border-radius: 6px; /* Rounded corners */
      display: inline-block; /* Adjusts width to content */
      white-space: nowrap; /* Prevent wrapping */
    }
    
    .code-style a {
      text-decoration: none; /* Remove underline */
      color: inherit; /* Inherit text color */
    }
    
    .code-style a:hover {
      text-decoration: underline; /* Add underline on hover */
    }

    .mainnet {
      margin-bottom: 30px;
    }

    h3 {
      color: #FFF;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      margin-top: 45px;

      .green {
        color: #2DD98F;
      }
    }

    h6,
    dt {
      color: #FFF;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      margin: 20px 0;
    }

    p {
      color: #C0C0C0;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin: 18px 0px;
    }

    ul {
      li {
        color: #2DD98F;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin: 18px 0;
        list-style-type: disc;
        margin-left: 25px;

        .greypara {
          color: #C0C0C0;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }

    .alphabet {
      list-style-type: lower-alpha;
    }

    ol {
      li {
        color: rgba(255, 255, 255, 0.50);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin: 10px 0;
        // list-style-type: decimal;
        margin-left: 40px;
      }

      .greentext {
        color: #2DD98F;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 21.6px */
      }
    }

    pre {
      background-color: #000;
      padding: 20px;
      max-width: 700px;
      width: 100%;
      margin: 0 auto;

      &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
      }


      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }


      &::-webkit-scrollbar-thumb {
        background: #ff0083;
        border-radius: 10px;
      }


      &::-webkit-scrollbar-thumb:hover {
        background: #ff0083;
      }


      code {
        color: #ff0083;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin: 40px 0;
    }

    table,
    th,
    td {
      border: unset;
    }

    tr {}

    th,
    td {
      padding: 8px;
      text-align: left;
      color: #fff;
      padding: 22px 30px;
      word-break: break-all;
      color: #C0C0C0;
      font-size: 18px;
      font-style: normal;
      background: #0F0F0F;
      font-weight: 400;
      line-height: 120%;
      /* 21.6px */
    }

    th {
      background: #151515;
      color: #FFF;

      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 21.6px */
    }


    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .inside-content {
        flex: 1;
        padding-right: 55px;

        h1 {
          color: #FFF;

          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;

          /* 31.2px */
          .prismaspan {
            color: #C0C0C0;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 21.6px */
          }
        }

        .featuress {
          padding-top: 20px;
        }

        a {
          color: #2DD98F;
        }

        p {
          color: #C0C0C0;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;

          span {
            color: #2DD98F;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }
        }
      }


      .right-sidebar {
        max-width: 198px;
        width: 100%;
        position: sticky;
        top: 30px;

        .main-heading {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          margin-bottom: 15px;

          svg {
            path {
              stroke: #ff0083;
            }
          }

          span {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
          }
        }

        .rightlinks {
          border-left: 1px solid #1D1D1D;
          padding: 6px 0px;

          a {
            display: flex;
            padding: 10px 0px 10px 12px;
            align-items: center;
            color: #7D7D7D;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            cursor: pointer;

            &.active-link {
              background: #0D0D0D;
              color: #FFF;
            }
          }
        }
      }

    }
  }
}

.docstabs {
  border-bottom: 1px solid #1D1D1D;
  backdrop-filter: blur(10px);
  display: flex;
  padding: 8px 40px 8px 40px;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }

  .nav-item {
    .nav-link {
      display: flex;
      padding: 12px;
      align-items: center;
      justify-content: center;
      color: #7D7D7D;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      border-radius: 0;
      border: none;
      outline: none;

      span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.docstabs .nav-item.show .nav-link,
.docstabs .nav-link.active {
  background: #0D0D0D;
  color: #FFF;

  span {
    svg path {
      stroke: #FFF;
    }
  }
}


@media (max-width:1024px) {
  .docstabs {
    padding: 8px 15px;
  }
h5{
  color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 19.2px */
}
  .app {
    flex-direction: column;
    padding: 0px;
    gap: 30px;

    .feraturesmain {
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }

  .app .content .content-wrapper .inside-content p span {
    font-size: 16px;
  }

  .app .content .bottombtns {
    flex-direction: column;
  }

  .app .content .feraturesmain .featuresinnerhead {
    gap: 50px;
    width: 700px;
  }

  .app .content .feraturesmain .featuresinner {
    gap: 50px;
    width: 700px;
  }

  .app .content .feraturesmain .featuresinner .para {
    width: 500px;
  }

  .app .content .feraturesmain .featuresinnerhead .para {
    width: 500px;
  }

  .app .content .feraturesmain .featuresinnerhead .heading {
    width: 200px;
  }

  .app .content .feraturesmain .featuresinner .heading {
    width: 200px;
  }

  .app .content .feraturesmain .featuresinner .featurespara {
    font-size: 16px;
  }

  .app .content .feraturesmain .featuresinnerhead .featurehead {
    font-size: 16px;
  }

  .app .content h2 {
    font-size: 30px;
  }

  .app .sidebar {
    display: none;
  }

  .app .content .content-wrapper .right-sidebar {
    display: none;
  }

  .app .content {
    flex: auto;
    width: 100%;
    padding: 0px 15px;
    padding-bottom: 50px;
  }

  .app .content .content-wrapper .inside-content {
    padding: 0;
    flex: auto;
    width: 100%;
  }

  .app .content p,
  .app .content dd {
    white-space: wrap;
    word-break: break-all;
  }

  .app .content .content-wrapper .inside-content p,
  .app .content .content-wrapper .inside-content dd {
    font-size: 16px;
  }

  .app .content h3 {
    font-size: 22px;
  }

  .app .content ul li {
    font-size: 16px;
  }

  .app .content h6,
  .app .content dt {
    font-size: 16px;
  }

  .mobile-sidebar {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    width: 100%;
  }

  .app .content p,
  .app .content dd {
    font-size: 16px;
  }

  .app .content ol li {
    font-size: 16px;
  }

  .app .content .bottombtns .innerbtn {
    padding: 15px;
  }
}


.mobile-offcanvas {
  background: #000;

  .offcanvas-header {
    .btn-close {
      //   background: url("../src/assets/close-icon.svg");
      opacity: 1;
      box-shadow: none;
    }
  }

  .offcanvas-body {
    padding: 15px;
  }

  .accordion {

    .accordion-item {
      border-radius: 0;
      border: none;
      background-color: transparent;
      margin-bottom: 20px;

      .accordion-button {
        box-shadow: none;
        background-color: transparent;
        padding: 0px;
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        padding-bottom: 12px;


        &::after {
          background: url("../../assets/arrowdown.svg");
        }
      }

      .accordion-button:not(.collapsed)::after {
        //   background: url("../src/assets/minus-icon.svg");
      }

      .accordion-body {
        padding: 6px 0px;
        border-left: 1px solid #1D1D1D;


        .inneraccordion {
          margin-bottom: 0;
          border-radius: 0;

          .accordion-button {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            padding: 10px 0px 10px 12px;
            border-radius: 0;

            &::after {
              background: url("../../assets/arrowdown.svg");
            }

          }

          .accordion-body {
            padding: 0;

            a {
              padding: 10px 0px 10px 25px;
              border-radius: 0;
            }
          }

          .accordion-button:not(.collapsed) {
            background: #0D0D0D;
          }

        }

        a {
          padding: 12px 10px;
        }
      }
    }
  }

  a {
    display: block;
    color: rgba(255, 255, 255, 0.50);
    // &:hover{
    //   color: rgba(255, 255, 255, 0.50);
    // }
  }

  .active {
    background: #0D0D0D;
    color: #FFF;
  }

  .active-link {
    background: #0D0D0D;
    color: #FFF;
  }
}