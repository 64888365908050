.applyjob {
    padding: 60px 0px;
    background: #000;

    .parentsapplyjob {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 71px;

        .left {
            max-width: 691px;
            width: 100%;

            a {
                color: #4DD388;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                outline: none;
                box-shadow: none;


            }

            h2 {
                margin-top: 25px;
                color: #FFF;
                margin-bottom: 20px;
                font-size: 50px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
                max-width: 691px;
                width: 100%;
            }

            p {
                color: #BDBDBD;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                margin-bottom: 25px;
            }

            .buttontwice {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                margin-bottom: 50px;

                .forwidth {
                    max-width: 95px;
                    width: 100%;
                }

                button {
                    background: rgba(255, 255, 255, 0.06);
                    padding: 8px;
                    max-width: 130px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    border: none;
                    white-space: nowrap;
                }
            }

            .maintextparent {
                .innertextmain {
                    margin-bottom: 35px;

                    &:last-child {
                        margin-bottom: 0px !important;
                    }

                    h2 {
                        color: #FFF;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 130%;
                        margin-bottom: 15px;
                    }

                    p {
                        color: #BDBDBD;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        max-width: 691px;
                        width: 100%;

                        span {
                            color: #FFF;
                        }
                    }

                    ul {
                        list-style-type: disc;
                        list-style-position: outside;
                        margin-left: 20px;

                        li {
                            color: #FFF;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            margin-bottom: 15px;

                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }

        .right {
            flex: 1;
            position: sticky;
            top: 20px;
            height: 500px;

            .mainboxes {
                border: 1px solid #2DD98F;
                background-color: rgba(0, 0, 0, 0.80);
                padding: 25px;
                max-width: 436px;
                width: 100%;
                background: url(../../../src/assets/bgbgbg.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                h2 {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%;
                    /* 28.6px */
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }

                p {
                    color: #CCC;
                    margin-bottom: 22px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%
                }

                .maininputssss {
                    .innerinputs {
                        margin-bottom: 22px;

                        .parentmaininput {
                            position: relative;

                            .wrapper {
                                .uploadbtn {
                                    color: #4DD388;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 100%;
                                    position: absolute;
                                    top: 20px;
                                    right: 20px;
                                    cursor: pointer;
                                }
                            }
                        }

                        p {
                            color: #FFF;
                            margin-bottom: 15px;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                        }

                        input {
                            border: 1px solid rgba(255, 255, 255, 0.07);
                            background: rgba(0, 0, 0, 0.60);
                            padding: 18px 16px;
                            width: 100%;
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;

                            &::placeholder {
                                color: #616161;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 100%;
                            }
                        }
                    }

                    .applybtn {
                        background: #4DD388;
                        padding: 15px 25px;
                        width: 100%;
                        border: none;
                        color: #111114;
                        text-align: center;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 14px */
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media(max-width:600px) {
    .applyjob .parentsapplyjob {
        flex-direction: column;
    }

    .applyjob .parentsapplyjob .left h2 {
        font-size: 30px;
    }

    .applyjob .parentsapplyjob .right {
        width: 100%;
    }
}