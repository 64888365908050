.mainevents {
    padding-top: 40px;
padding-bottom: 95px;
    .mainhead {
        color: #FFF;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 25px */
        text-transform: uppercase;
        margin-bottom: 30px;

        span {
            color: #2DD98F;

            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .uppercard {
        border: 1px solid #1E1E1E;
        height: 466px;
        background: #000;
        position: relative;
        overflow: hidden;

        .eventimg {
            width: 1066px;
            height: 466px;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 0px;
            left: 0px;

            .imagess {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .maincontent {
            padding-left: 45px;
            position: absolute;
            bottom: 62px;

            .date {
                border-radius: 100px;
                background: rgba(255, 255, 255, 0.13);
                backdrop-filter: blur(10px);
                padding: 8px 12px;
                color: #2DD98F;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                border: none;
                margin-bottom: 20px;
            }

            h2 {
                color: #FFF;
                font-size: 35px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                max-width: 485px;
                width: 100%;
                margin-bottom: 24px;
            }

            p {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }
        }
    }

    .community {
        margin-top: 45px;

        h2 {
            color: #FFF;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            /* 25px */
            text-transform: uppercase;
margin-bottom: 30px;
            span {
                color: #2DD98F;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
            }
        }

        .parents {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            justify-content: flex-start;
            align-items: center;

            .cccards {
                border: 1px solid #0F0F0F;
                padding: 13px 15px;
                background: #000;
                width: 100%;

                &:hover {
                    border: 1px solid #2DD98F;
                }

                .innerparent {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 19px;

                    .left {
                        width: 120px;
                        height: 120px;
                        object-fit: cover;
                        object-position: center;
                        overflow: hidden;
flex-shrink: 0;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .right {
                        h3 {
                            color: #E4E4E4;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 120%;
                            margin-bottom: 48px;
                        }

                        p {
                            color: #2DD98F;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 8px;
                            margin-bottom: 10px;
                        }

                        h6 {
                            color: #FFF;

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 8px;
                        }
                    }
                }
            }
        }
    }
}
@media(max-width:600px){
    .mainevents .community .parents{
        grid-template-columns: 1fr;
    }
    .mainevents .uppercard .maincontent{
        padding-left: 15px;
    }
    .mainevents .community .parents .cccards .innerparent .right h3{
        font-size: 16px;
    }
    .mainevents .uppercard .maincontent h2{
        font-size: 28px;
    }
}