.maincareer {
    background: url(../../../src/assets/resourcebg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    overflow: hidden;
    height: 326px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .mainhead {
        color: #FFF;
        font-size: 62px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        max-width: 291px;
        width: 100%;
        text-transform: uppercase;
        white-space: nowrap;
        text-align: center;
    }
}

.innertextttt {
    background: url(../../../src/assets/background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;
    padding: 95px 0px;
    height: 838px;
    overflow: hidden;

    .innertextparent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        .twiceinput {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 15px;

            .left {
                p {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 15px;
                }

                input {
                    border: 1px solid rgba(255, 255, 255, 0.07) !important;
                    background: #030303;
                    width: 393px;
                    padding: 29px 20px;
                    color: #fff;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    outline: none;
                    box-shadow: none;
                    transition: 0.3s linear;

                    &::placeholder {
                        color: rgba(189, 189, 189, 0.30);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }

                    &:focus {
                        border: 1px solid #2DD98F !important;
                        background: #030303;

                        &::placeholder {
                            color: #2DD98F;
                        }
                    }
                }
            }
        }

        .inputsssss {
            margin: 22px 0px;

            p {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 15px;
            }

            input {
                border: 1px solid rgba(255, 255, 255, 0.07) !important;
                background: #030303;
                width: 800px;
                padding: 29px 20px;
                color: #fff;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                outline: none;
                box-shadow: none;
                transition: 0.3s linear;

                &:focus {
                    border: 1px solid #2DD98F !important;
                    background: #030303;

                    &::placeholder {
                        color: #2DD98F;
                    }
                }

                &::placeholder {
                    color: rgba(189, 189, 189, 0.30);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }

        .texttttt {
            p {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 15px;
            }

            textarea {
                border: 1px solid rgba(255, 255, 255, 0.07);
                background: #030303;
                padding: 29px 20px;
                color: #fff;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                width: 800px;
                height: 240px;
                outline: none;
                box-shadow: none;
                transition: 0.3s linear;

                &:focus {
                    border: 1px solid #2DD98F !important;
                    background: #030303;

                    &::placeholder {
                        color: #2DD98F;
                    }
                }

                &::placeholder {
                    color: rgba(189, 189, 189, 0.30);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }

        .getin {
            background: #FFF;
            padding: 15px;
            width: 800px;
            color: #00110A;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            text-transform: uppercase;
            margin-top: 35px;
        }
    }

}

@media(max-width:991px) {
    .innertextttt .innertextparent .twiceinput {
        flex-direction: column;
        width: 100%;
    }
    .maincareer .mainhead{
        text-align: start;
       
    }

    .maincareer .mainhead {
        font-size: 26px;
    }

    .innertextttt .innertextparent .inputsssss input {
        width: 100%;
    }

    .innertextttt .innertextparent .texttttt textarea {
        width: 100%;
    }

    .innertextttt .innertextparent .texttttt {
        width: 100%;
    }

    .innertextttt .innertextparent .inputsssss {
        width: 100%;
    }

    .innertextttt .innertextparent .getin {
        width: 100%;
    }

    .innertextttt {
        padding: 25px 0px;
    }

    .innertextttt .innertextparent .twiceinput .left input {
        width: 100%;
    }

    .innertextttt .innertextparent .twiceinput .left {
        width: 100%;
    }
}

@media(max-width:600px) {
    .innertextttt .innertextparent .twiceinput {
        flex-direction: column;
        width: 100%;
    }

    .maincareer {
        background-position: right;
        align-items: flex-start;
        padding-top: 80px;
    }

    .innertextttt{
        padding: 60px 0 !important;
        height: auto;
    }

    .maincareer .mainhead {
        font-size: 26px;
    }

    .innertextttt .innertextparent .inputsssss input {
        width: 100%;
        padding: 15px;
    }

    .innertextttt .innertextparent .texttttt textarea {
        width: 100%;
        padding: 15px;
        height: 115px;
        font-size: 14px;
    }

    .innertextttt .innertextparent .texttttt {
        width: 100%;
    }

    .innertextttt .innertextparent .inputsssss {
        width: 100%;
    }

    .innertextttt .innertextparent .getin {
        width: 100%;
    }

    .innertextttt {
        padding: 25px 0px;
    }

    .innertextttt .innertextparent .twiceinput .left input {
        padding: 15px;
        font-size: 14px;
    }

    .innertextttt .innertextparent .twiceinput .left p {
        font-size: 14px;
    }

    .innertextttt .innertextparent .twiceinput .left input {
        font-size: 14px;
    }

    .innertextttt .innertextparent .inputsssss p {
        font-size: 14px;
    }

    .innertextttt .innertextparent .texttttt p {
        font-size: 14px;
    }

}