.validator-section {
    position: relative;
    overflow: hidden;
    .part1_section {
        position: relative;

        .star {
            position: absolute;
            right: 0px;

        }

        .imgelip {
            position: absolute;
            right: 0px;
            bottom: 0px;
        }

        .part1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 50px;

            .left_side {
                // padding-top: 90px;

                h1 {
                    color: #2DD98F;

                    font-size: 62px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    /* 62px */
                    text-transform: uppercase;
                }

                h2 {
                    color: #FFF;

                    font-size: 62px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    text-transform: uppercase;
                }

                p {
                    color: #FFF;
                    max-width: 438px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 16.8px */
                    padding-top: 30px;
                    padding-bottom: 57px;
                }

                button {
                    color: #00110A;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 15px 15px 15px 30px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    background: #2DD98F;
                    border: unset;
                }
            }

            .right_side {

                padding-top: 74px;
                flex-shrink: 0;

            }
        }
    }
    .part2_section {
        position: relative;

        .imgelip2 {
            position: absolute;
            width: 100%;
            z-index: -1;
            top: 0px;
        }

        .part2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 50px;
            padding-bottom: 50px;

            .left_side {
                padding-top: 90px;
                width: 532px;

                span {
                    color: #2DD98F !important;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 120%;
                    text-transform: uppercase;
                }

                h1 {
                    color: #2DD98F;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    /* 62px */
                    text-transform: uppercase;
                }

                h2 {
                    color: #FFF;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    text-transform: uppercase;
                    padding-bottom: 29px;
                }

                p {
                    color: #FFF;
                    max-width: 438px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 16.8px */
                    padding-top: 20px;
                }

                h3 {
                    color: rgba(255, 255, 255, 0.50);
                    padding-bottom: 14px;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                }

                button {
                    color: #00110A;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 15px 15px 15px 30px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    background: #2DD98F;
                    border: unset;
                }
            }

            .right_side {

                padding-top: 74px;

            }
        }
    }
    .part3_section {
        position: relative;

        .lastelip {
            position: absolute;
            bottom: 0px;

        }

        .lastelip2 {
            position: absolute;
            bottom: 0px;
            width: 100%;
            z-index: -1;
        }

        .lineelip {
            position: absolute;
            width: 100%;
            top: 0px;
        }

        .part3 {
            padding-top: 106px;
            padding-bottom: 115px;

            h1 {
                color: #FFF;
                font-size: 42px;
                padding-bottom: 62px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 50.4px */
                text-transform: uppercase;
            }

            h2 {
                color: #FFF;

                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 22px */
                text-transform: uppercase;
            }

            p {
                color: #BDBDBD;
                font-size: 18px;
                padding-top: 20px;
                padding-bottom: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 21.6px */
            }

            button {
                color: #FFF;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                /* 14.4px */
                text-transform: uppercase;
                display: flex;
                padding: 12px 15px 12px 25px;
                justify-content: center;
                align-items: center;
                gap: 15px;
                border: 1px solid #FFF;
                background-color: unset;
            }

            .card1 {
                height: 295px;
                padding: 47px 29px;
                position: relative;
                border: 1px solid #1F1F1F;

                // background: url(../../assets/Rectangle.png) lightgray 0% 0% / 100px 100px repeat;
            }

            .boxxcard {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                // opacity: 0.5;
                z-index: 0;
                object-fit: cover;
                z-index: -1;
                pointer-events: none;
            }

            .inner_part3 {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;
            }
        }
    }
    .part4_section {
        .part4 {
            padding-top: 100px;
            padding-bottom: 100px;

            .accordion-item {
                background: #000 !important;
                box-shadow: unset;
                border-radius: unset;
                border: unset;
                margin-bottom: 15px;
                color: #BDBDBD;
                padding: 0px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
            }

            .accordion-header {
                border: 1px solid #1F1F1F;
                color: #BDBDBD;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;

                /* 22.4px */
                span {
                    color: #FFF;
                    padding-right: 15px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 22.4px */
                }
            }

            .accordion-button:focus {
                box-shadow: unset;
            }

            .accordion-button:not(.collapsed) {
                border: 1px solid #2DD98F;
                box-shadow: unset;
                background: #000;
                border-radius: unset;
            }

            .accordion-button {
                background-color: unset;
                color: #BDBDBD;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
            }

            .accordion-button::after {
                display: none;
            }

            .accordion-body {
                border: unset;
                padding: 15px 0px;
            }

            h1 {
                color: #FFF;
                padding-bottom: 30px;
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 50.4px */
                text-transform: uppercase;
            }

            p {
                color: #BDBDBD;
                padding-bottom: 24px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
            }
        }
    }
    .part5_section {
        position: relative;

        .lastelip2 {
            position: absolute;
            bottom: 0px;
            width: 100%;
            z-index: -1;
        }

        .lastelip {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            max-width: 100%;
            height: auto;
        }
        position: relative;

        .lineelip {
            position: absolute;
            top: 0px;
            width: 100%;
        }

        .part5 {
            padding-top: 100px;
            padding-bottom: 116px;
            .innersection {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                padding-top: 20px;
            }

            .card2 {
                padding: 30px;
                border: 1px solid #1F1F1F;
                position: relative;

                .imgcarddd {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                    z-index: 0;
                    object-fit: cover;
                    z-index: -1;
                    pointer-events: none;
                }

                h1 {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    /* 20px */
                    text-transform: uppercase;
                }

                p {
                    color: #BDBDBD;
                    padding-top: 20px;
                    padding-bottom: 54px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 19.2px */
                }

                button {
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 12px 15px 12px 25px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    background-color: unset;
                    border: 1px solid #FFF;
                }
            }

            .card2:hover {
                border: 1px solid #2DD98F;

            }
        }
    }
}

@media (max-width: 1024px) {
    .star {
        position: absolute;
        bottom: 0px !important;

    }
    .validator-section .part3_section .part3 .card1 {
        height: 295px;
        padding: 33px 20px;
        position: relative;
        border: 1px solid #1F1F1F;
    }   
    .validator-section .part4_section .part4 {
        padding-top: 68px;
        padding-bottom: 70px;
    }

    .left_side {
        padding-top: 50px;
    }

    .validator-section .part1 {
        flex-direction: column !important;
    }

    .validator-section .part1 .left_side h2 {
        font-size: 40px !important;
    }

    .validator-section .part1 .left_side h1 {
        font-size: 40px !important;
        ;
    }

    .validator-section .part2 {
        flex-direction: column-reverse;
        margin-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .validator-section .part2 .left_side {
        width: 100% !important;
    }

    .validator-section .part2_section .part2 .left_side h2 {
        color: #FFF;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        text-transform: uppercase;
        padding-bottom: 29px;
    }

    .validator-section .part2_section .part2 .left_side span {
        color: #2DD98F !important;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        text-transform: uppercase;
    }

    .validator-section .part2_section .part2 .right_side {
        padding-top: 0px;
    }

    .validator-section .part2 .right_side .imgelip2 {
        position: absolute;
        width: 100%;
        z-index: -1;
        right: 0px;
        left: 0px;
        top: 796px;
    }

    .validator-section .part1 .right_side .imgelip {
        position: absolute;
        right: 0px;
        height: 905px;
    }

    .validator-section .part3_section .part3 {
        padding-top: 46px;
        padding-bottom: 28px;
    }

    .validator-section .part3 h1 {
        color: #FFF;
        font-size: 30px !important;
        padding-bottom: 62px;
        font-style: normal;
        text-align: center;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    .validator-section .part3 .inner_part3 {
        display: grid !important;
        grid-template-columns: 1fr !important;
        gap: 20px;
    }

    .validator-section .part4 h1 {
        color: #FFF;
        padding-bottom: 30px;
        font-size: 33px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    .validator-section .part5 .innersection {
        display: grid;
        grid-template-columns: 1fr !important;
        gap: 20px;
        padding-top: 20px;
    }
    .validator-section .part5_section .part5 {
        padding-top: 44px;
        padding-bottom: 35px;
    }
}