.privacy-section {
    .devneeet{
        padding-top: 40px !important;
    }
    .mydivvvv{
        padding-bottom: 40px !important;
    }
    .paraaagraphhh{
        padding-bottom: 40px !important;
    }
    .inner_pro {
        h1 {
            color: #FFF;
            padding-top: 112px;
            padding-bottom: 152px;
            font-size: 62px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            /* 62px */
            text-transform: uppercase;
        }
   

        .planets {
            position: absolute;
            right: 0px;
            top: 72px;
        }


    }

    .innner_new {
        padding-top: 95px;
        padding-bottom: 95px;

        p {
            color: #E4E4E4;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 24px */
            padding-bottom: 15px;
        }
        h3{
            color: #E4E4E4;
font-size: 20px;
font-style: normal;
padding-bottom: 15px;
font-weight: 500;
line-height: 120%; /* 24px */
        }
        h1 {
            color: #FFF;
     
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 24px */
            padding-top: 20px;
            padding-bottom: 20px;
        }

        span {
            color: #2DD98F !important;

            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }

        li {
            color: #2DD98F;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            cursor: pointer;
            padding-top: 10px;
            line-height: 120%;

            /* 24px */
            span {
                color: #E4E4E4 !important;
                padding-left: 4px;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }
        }
    }
}
@media (max-width: 1024px){
    .privacy-section .inner_pro h1 {
        color: #FFF;
        padding-top: 38px;
        padding-bottom: 152px;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
    }
    .privacy-section .innner_new p {
        color: #E4E4E4;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        padding-bottom: 12px;
    }
    .privacy-section .innner_new li {
        color: #2DD98F;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
        padding-top: 10px;
        line-height: 120%;
    }
}