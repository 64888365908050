.wallet-section {
    position: relative;
    overflow: hidden;

    .part1_section {
        position: relative;

        .imgelip9999 {
            position: absolute;
            right: 0px;
            width: 100%;
            z-index: -1;
            bottom: 0px;
        }

        .imgelip {
            position: absolute;
            bottom: 0px;
            width: 100%;
        }

        .part1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 50px;


            .left_side {
                padding-top: 90px;

                h1 {
                    color: #2DD98F;

                    font-size: 62px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    /* 62px */
                    text-transform: uppercase;
                }

                h2 {
                    color: #FFF;

                    font-size: 62px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    text-transform: uppercase;
                }

                p {
                    color: #FFF;
                    max-width: 438px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 16.8px */
                    padding-top: 30px;
                    padding-bottom: 57px;
                }

                button {
                    color: #00110A;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 15px 15px 15px 30px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    background: #2DD98F;
                    border: unset;
                }
            }

            .right_side {
                flex-shrink: 0;
                padding-top: 74px;


            }
        }
    }

    .part2_section {
        position: relative;

        .imgelip2 {
            position: absolute;
            width: 100%;
            z-index: -1;
            right: 0px;
            left: 0px;
            top: 648px;
        }

        .imgelip77777 {
            position: absolute;
            z-index: -1;
            width: 100%;
        }

        .part2 {
            padding-top: 80px;
            padding-bottom: 181px;
            display: flex;
            align-items: center;
            justify-content: space-between;


            .left_side {
                padding-top: 90px;
                width: 532px;

                span {
                    color: #2DD98F !important;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 120%;
                    text-transform: uppercase;
                }

                h1 {
                    color: #2DD98F;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    /* 62px */
                    text-transform: uppercase;
                }

                h2 {
                    color: #FFF;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 120%;
                    text-transform: uppercase;
                    padding-bottom: 29px;
                }

                p {
                    color: #FFF;
                    max-width: 438px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 16.8px */
                    padding-top: 20px;
                }

                h3 {
                    color: rgba(255, 255, 255, 0.50);
                    padding-bottom: 14px;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                }

                button {
                    color: #00110A;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 15px 15px 15px 30px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    margin-top: 49px;
                    border: unset;
                }
            }

            .right_side {

                padding-top: 74px;


            }
        }
    }


    .part3_section {
        position: relative;

        .lineelip {
            position: absolute;
            top: 0px;
            width: 100%;
            pointer-events: none;
        }

        .cardsdiv {
            position: absolute;
            width: 100%;
            z-index: -1;
        }

        .part3 {
            padding-top: 106px;
            margin-left: 189px;

            .slick-next {
                right: 189px;
                background-image: url(../../assets/ArrowRIGHT.png) !important;
                width: 56px !important;
                height: 55px !important;
                border: 1.5px solid #121212;

                background-repeat: no-repeat !important;
            }

            .slick-next:hover {
                border: 1.5px solid #2DD98F;
            }

            .slick-prev:hover {
                border: 1.5px solid #2DD98F;
            }

            .slick-track {
                position: unset;

            }

            .slick-prev {
                right: 260px;
                border: 1.5px solid #121212;

                left: unset;
                background-image: url(../../assets/Arrowleft.png) !important;
                width: 56px !important;
                height: 55px !important;
                background-repeat: no-repeat !important;
            }

            .slick-prev:before {
                background-image: url(../../assets/Arrowleft.png);
                content: unset;
            }

            .slick-next:before {
                content: unset;
            }

            .slick-prev,
            .slick-next {
                font-size: 0;
                line-height: 0;
                position: absolute;
                top: -29%;
                display: block;
                width: 20px;
                height: 20px;
                padding: 0;
                transform: translate(0, -50%);
                cursor: pointer;
                color: transparent;
               
                outline: none;
                background: transparent;
            }

            .lastelip {
                position: absolute;
                bottom: 0;
                pointer-events: none;
                left: 50%;
                transform: translateX(-50%);
                max-width: 100%;
                height: auto;
            }

            padding-bottom: 115px;

            h1 {
                color: #2DD98F;
                padding-bottom: 62px;
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 50.4px */
                text-transform: uppercase;

                span {
                    color: #FFF;

                    font-size: 42px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                }
            }

            h2 {
                color: #FFF;
                padding-top: 40px;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 22px */
                text-transform: uppercase;
            }

            p {
                color: #BDBDBD;
                font-size: 18px;
                padding-top: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 21.6px */
            }

            button {
                color: #FFF;
                padding-bottom: 62px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                /* 14.4px */
                text-transform: uppercase;
                display: flex;
                padding: 12px 15px 12px 25px;
                justify-content: center;
                align-items: center;
                gap: 15px;
                border: 1px solid #FFF;
                background-color: unset;
            }

            .card1 {
                // height: 295px;
                padding: 29px 29px;
                position: relative;
                border: 1px solid #1F1F1F;
                margin-left: 20px;
            }

            .boxxcard {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                z-index: -1;
                object-fit: cover;
            }

            .inner_part3 {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;
            }
        }
    }



}

@media (max-width: 1024px) {

    .wallet-section .part1 {
        flex-direction: column !important;
    }

    .wallet-section .part1 .left_side h2 {
        font-size: 30px !important;
    }

    .wallet-section .part1 .left_side h1 {
        font-size: 30px  !important;
    }

    .wallet-section .part2 {
        flex-direction: column  !important;
        margin-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .wallet-section .part2 .left_side {
        width: 100% !important;
        padding-top: 0px !important;
    }
    .wallet-section .part2_section .part2 .left_side h2 {
        color: #FFF;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        text-transform: uppercase;
        padding-bottom: 29px;
    }
    .wallet-section .part2 .right_side .imgelip2 {
        position: absolute;
        width: 100%;
        z-index: -1;
        right: 0px;
        left: 0px;
        top: 796px;
    }

    .wallet-section .part1 .right_side .imgelip {
        position: absolute;
        right: 0px;

    }

    .wallet-section .part3 .slick-prev {
        left: 98px;
        display: none !important;
        background-repeat: no-repeat !important;
    }

    .wallet-section .part3 .slick-next {
        display: none !important;
    }

    .wallet-section .part3 .card1 {
        margin-right: 20px;
    }

    .wallet-section .part3 {
        position: relative;
        margin-top: 56px;
        padding-bottom: 0px !important;
        padding-top: 39px !important;
        margin-left: 0px !important;
    }

    .wallet-section .part3 h1 {
        // color: #FFF;
        // font-size: 32px;
        padding-bottom: 62px;
        font-style: normal;
        text-align: center;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    .wallet-section .part3 .inner_part3 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .wallet-section .part4 h1 {
        color: #FFF;
        padding-bottom: 30px;
        font-size: 33px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    .wallet-section .part5 .innersection {
        display: grid;
        grid-template-columns: 1fr  !important;
        gap: 20px;
        padding-top: 20px;
    }
    .wallet-section .part1_section .part1 .right_side {
        flex-shrink: 0;
        padding-top: 0px;
    }
}