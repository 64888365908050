.tempor-section{
    position: relative;
    .imgelip2 {
        position: absolute;
        width: 100%;
        z-index: -1;
      top: 0px;
    }
    .part2 {
        padding-top: 200px;
padding-bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left_side {
            padding-top: 90px;
            width: 532px;

            span {
                color: #2DD98F !important;

                font-size: 40px;
                font-style: normal;
                font-weight: 900;
                line-height: 120%;
                text-transform: uppercase;
            }

            h1 {
                color: #2DD98F;

                font-size: 40px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                /* 62px */
                text-transform: uppercase;
            }

            h2 {
                color: #FFF;

                font-size: 40px;
                font-style: normal;
                font-weight: 900;
                line-height: 120%;
                text-transform: uppercase;
                padding-bottom: 29px;
            }

            p {
                color: #FFF;
                max-width: 438px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 16.8px */
                padding-top: 20px;
            }

            h3 {
                color: rgba(255, 255, 255, 0.50);
                padding-bottom: 14px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                /* 14.4px */
                letter-spacing: 1.2px;
                text-transform: uppercase;
            }

            button {
                color: #00110A;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                /* 14.4px */
                text-transform: uppercase;
                display: flex;
                padding: 15px 15px 15px 30px;
                justify-content: center;
                align-items: center;
                gap: 15px;
             margin-top: 49px;
                border: unset;
            }
        }

        .right_side {

            padding-top: 74px;

        
        }
    }
}
@media (max-width: 1024px){
    .tempor-section .part2 {
        margin-top: 50px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column-reverse;
        padding-bottom: 0px;
        padding-top: 135px;
    }
    .tempor-section .part2 .left_side {
        padding-top: 0px;
        width: 100%;
    }
    .tempor-section .part2 .left_side h2 {
        color: #FFF;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        text-transform: uppercase;
        padding-bottom: 29px;
    }
}