.mainlearn {
    background: url(../../../src/assets/learnbg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 70px 0px;


    .mainhead {
        color: #FFF;
        font-size: 62px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 62px */
        text-transform: uppercase;
        max-width: 689px;
        width: 100%;
    }
    .onlyfor_width{
        max-width: 720px !important;
        width: 100%;
    }
}

.parenttext {
    background: #000;
    padding: 95px 0px;
    

    p {
        color: #E4E4E4;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0px;
        }

        .green {
            color: #2DD98F;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }
    }
    .bold{
        color: #E4E4E4;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 20px;
    }
    .mainhead{
        color: #E4E4E4;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 20px;
        margin-top: 50px;
    }
    ul{
        list-style-type: disc;
        list-style-position: outside;
       margin-left: 20px;
        li{
            color: #E4E4E4;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 20px;

    

    span {
            color: #2DD98F;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }
        }
    }
}


@media (max-width:600px){
    .mainlearn .mainhead{
        font-size: 32px;
    }
    .parenttext p{
        font-size: 16px;
    }
    .mainlearn{
        background-position: right;
    }
    .parenttext .bold{
        font-size: 16px;
    }
    .parenttext .mainhead{
        font-size: 24px;
    }
    .parenttext ul li{
        font-size: 16px;
    }
}