.our-partners {
    position: relative;
    z-index: 99;
    padding-top: 80px;
    padding-bottom: 62px;

    .partners-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: -1;
    }

    .main-heading {
        margin-bottom: 37px;

        p {
            color: rgba(255, 255, 255, 0.50);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        h4 {
            color: #FFF;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .bottom-content {
        .parent-logo {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 10px;

            .single-logo {
                border: 1px solid rgba(255, 255, 255, 0.05);
                padding: 33px;
                border-radius: 12px;
                transition: 0.3s;
                overflow: hidden;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    opacity: 0.6;
                    transition: opacity 0.8s ease;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.04) 100%);
                    transition: opacity 0.8s ease;
                    opacity: 0;
                    z-index: 0;
                    border-radius: 12px;
                }
                &:hover{
                    &::before{
                        opacity: 1;
                    }
                    img{
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .our-partners .main-heading h4 {
        font-size: 30px;
    }

    .our-partners .bottom-content .parent-logo .single-logo {
        padding: 10px;
    }

    .our-partners .bottom-content .parent-logo {
        grid-template-columns: 1fr 1fr;
    }
    .our-partners .partners-bg{
        height: 100%;
    }
    .our-partners .partners-bg{
        display: none;
    }
    .our-partners .partners-bg-mobile{
        display: block !important;
    }
    .our-partners .main-heading{
        margin-bottom: 50px;
    }
    .our-partners{
        padding-bottom: 80px;
    }
    .our-partners .bottom-content .parent-logo .single-logo img{
        width: 118px;
        height: 29px;
    }
}