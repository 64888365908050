.main-banner{
    position: relative;
    padding-top: 89px;
    padding-bottom: 83px;
    z-index: 99;
    // overflow: hidden;
    width: 100%;
    .custom-container{
        position: relative;
    }
    .banner-spline{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0px;
        overflow: visible !important;
    }
    .left-content{
        max-width: 689px;
        width: 100%;
        position: relative;
        z-index: 999;
        h2{
            color: #FFF;
            font-size: 62px;
            font-style: normal;
            font-weight: 800;
            line-height: 100%; 
            text-transform: uppercase;
            span{
                color: #2DD98F;
                font-size: 62px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                text-transform: uppercase;
            }
        }
        p{
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; 
            margin-bottom: 40px;
            max-width: 497px;
            width: 100%;
            margin-top: 30px;
        }
        .green-btn{
            max-width: 187px;
            width: 100%;
        }
    }
    .navbar-toggler{
        box-shadow: none;
    }
}

@media (max-width:1024px){
    .main-banner{
        padding-top: 250px;
        overflow: hidden;
    }
    .main-banner .left-content h2{
        font-size: 34px;
        text-align: center;
    }
    .main-banner .left-content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .main-banner .left-content p{
        text-align: center;
        font-size: 18px;
    }
    .main-banner .banner-spline{
        position: absolute;
        right: unset;
        left: 50%;
        top: 0;
        transform: translate(-50%, -70%);
    }
    .main-banner .banner-spline canvas{
        width: 400px !important;
        height: 400px !important;
    }
    .main-banner .left-content h2 span{
        font-size: 30px;
    }
}


@media (max-width:400px){
    .main-banner .left-content h2{
        font-size: 30px;
    }
}