.our-thesis{
    position: relative;
    z-index: 99;
    padding-top: 284px;
    padding-bottom: 169px;
    overflow: hidden;
    .thesis-bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
    }
    .topshadow-blue{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: -2;
        pointer-events: none;
        width: 100%;
    }
    .parent{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-content{
            max-width: 626px;
            width: 100%;
            .main-heading{
                p{
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; 
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                }
                h4{
                    color: #FFF;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    text-transform: uppercase;
                    // margin-bottom: 49px;
                }
              
            }
            .para{
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; 
                margin-bottom: 20px;
              
            }
            .white-btn{
                max-width: 190px;
                margin-top: 34px;
            }
        }
        .right-content{
            flex: 1;
        }
    }
}

@media (max-width:1024px){
    .our-thesis{
        padding: 120px 0;
        padding-bottom: 50px;
    }
    .our-thesis .parent{
        flex-direction: column;
        gap: 50px;
    }
    .our-thesis .parent .left-content .main-heading h4{
        font-size: 24px;
    }
    .our-thesis .parent .left-content .para{
        font-size: 16px;
    }
    .our-thesis .thesis-bg{
        height: 100%;
    }
    .our-thesis .topshadow-blue{
        display: none;
    }
    .our-thesis .topshadow-bluemobile{
        display: block !important;
        top: -50px;
    }
    .our-thesis{
        overflow: visible;
        padding-top: 150px;
    }
}