.Updates-section {
    position: relative;
    z-index: 99;
    padding-top: 70px;
    padding-bottom: 127px;

    .updates-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        pointer-events: none;
    }

    .main-heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 62px;

        p {
            color: rgba(255, 255, 255, 0.50);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        h4 {
            color: #FFF;
            font-size: 45px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .bottom-cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        position: relative;
        overflow: hidden;

        .single-card {
            border: 1px solid rgba(255, 255, 255, 0.07);
            background: rgba(0, 0, 0, 0.80);
            transition: 0.8s linear;
            cursor: pointer;

            &:hover {
                border: 1px solid #2DD98F;
                background: rgba(0, 0, 0, 0.80);
                box-shadow: 0px 21px 30px -20px #2DD98F;
            }

            .main-img {
                width: 100%;
                height: 205px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .card-content {
                padding: 20px;

                h6 {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%;
                }

                .twice-text {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 15px;

                    p {
                        color: rgba(255, 255, 255, 0.50);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        text-transform: uppercase;
                    }

                    a {
                        color: #2DD98F;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 100%;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 5px;
                    }
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .Updates-section .main-heading h4 {
        font-size: 24px;
    }

    .Updates-section .bottom-cards {
        grid-template-columns: 1fr;
    }

    .Updates-section {
        padding-bottom: 80px;
    }

    .Updates-section .updates-bg {
        height: 100%;
    }

    .Updates-section .main-heading {
        align-items: flex-start;
    
    }

    .Updates-section .main-heading .inside-left {
        max-width: 200px;
    }

  
}




@media (max-width:600px) {
.Updates-section .main-heading {
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}

.Updates-section .main-heading .inside-left {
    max-width: 250px;
}

.green-btn {
   
    justify-content: space-between;
}
}