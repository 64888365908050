.maintech {
    background: url(../../../src/assets/bgtech.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100vh;

    .maincontent {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 236px;
        flex-direction: column;

        h2 {
            color: #FFF;
            text-align: center;
            font-size: 62px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            /* 62px */
            text-transform: uppercase;

            .green {
                color: #2DD98F;
                font-size: 62px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                text-transform: uppercase;
            }
        }

        p {
            color: #FFF;

            text-align: center;
            margin-top: 30px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }
}

.maincards {
    background: #000;
    padding: 95px 187px;
    overflow: hidden;
    .parentcardss {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .innercard {
            position: relative;
            border: 1px solid rgba(255, 255, 255, 0.07);
            background: rgba(0, 0, 0, 0.80);
            padding: 30px;
            width: 100%;
            z-index: 99;
            overflow: hidden;

            &:hover {
                border: 1px solid #2DD98F;
                background: rgba(0, 0, 0, 0.80);
            }

            .shade {
                position: absolute;
                bottom: 0px;
                right: 0px;
                z-index: -1;

            }

            .innercontentss {


                h2 {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    /* 20px */
                    text-transform: uppercase;
                    margin-top: 59px;
                }

                p {
                    color: #BDBDBD;

                    margin-top: 20px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }
    }
}
@media(max-width:991px){
    .maincards {
        padding: 95px 15px;
    }
    .maincards .parentcardss{
        flex-direction: column;
    }
    .maintech .maincontent h2{
        font-size: 30px;
    }
    .maintech .maincontent h2 .green{
        font-size: 30px;
    }
    .maincards .parentcardss .innercard{
        padding: 15px;
    }
}