.resource {
    background: url(../../../src/assets/resourcebg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding-top: 112px;
    padding-bottom: 180px;



    .mainhead {
        color: #FFF;
        font-size: 62px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 62px */
        text-transform: uppercase;
        max-width: 689px;
        width: 100%;

        span {
            color: #2DD98F;
        }
    }
}

.find {
    padding: 95px 0px;
    background: #000;

    .mainheading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
            color: #FFF;
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;

            span {
                color: #2DD98F;
            }
        }

        p {
            color: #BDBDBD;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            max-width: 452px;
            margin-top: 18Px;
            margin-bottom: 45px;
        }
    }

    .parentcardss {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .innercard {
            position: relative;
            border: 1px solid rgba(255, 255, 255, 0.07);
            background: rgba(0, 0, 0, 0.80);
            padding: 30px;
            width: 100%;
            z-index: 99;
            overflow: hidden;

            &:hover {
                border: 1px solid #2DD98F;
                background: rgba(0, 0, 0, 0.80);
            }

            .shade {
                position: absolute;
                bottom: 0px;
                right: 0px;
                z-index: -1;

            }

            .innercontentss {
                .mainimg {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    object-position: center;
                    overflow: hidden;
                    margin-bottom: 21px;
                    margin-top: 34px;

                    .innerimages {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .upperhead {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    border-radius: 41px;
                    background: rgba(255, 255, 255, 0.06);
                    padding: 6px 11px;
                    display: inline-block;


                }

                h5 {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    margin-bottom: 55px;
                    text-transform: uppercase;
                }

                p {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%
                }
            }
        }
    }
}

.videos {
    background: #000;
    padding: 95px 0px;

    .upperheadingvideo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 45px;

        .left {
            h2 {
                color: #FFF;
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;

                span {
                    color: #2DD98F;
                }
            }
        }

        .right {
            .twisebutton {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 18px;
               

                button {
                    border: 1px solid #121212;
                    background: #000;
                    padding: 12px 15px;
                    width: 100%;
                    color: #2DD98F;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 9px;

                    &:focus {
                        border: 1px solid #2DD98F;
                        background: #000;
                    }

                }
            }
        }
    }

    .parentsssss {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 35px;

        .left {
            h2 {
                color: #FFF;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                margin-top: 25px;
                margin-bottom: 30px;
            }

            .watchbutton {
                button {
                    border: 1px solid #FFF;
                    background: #000;
                    padding: 12px 15px;
                    max-width: 87px;
                    width: 100%;
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                }
            }

            .daoimg {
                max-width: 791px;
                width: 100%;
                height: 446px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;

                .innerdao {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .rig {

            .textttttttttttttttt {
                .brdr {
                    background: #121212;
                    width: 239px;
                    height: 1px;
                    margin: 26px 0px;
                }

                .innerttext {

                    p {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 130%;
                        max-width: 239px;
                        width: 100%;
                        margin-bottom: 25px;
                    }

                    button {
                        border: 1px solid #FFF;
                        background: #000;
                        padding: 12px 15px;
                        max-width: 87px;
                        width: 100%;
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        /* 14.4px */
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media(max-width:600px) {
    .resource .mainhead {
        font-size: 26px;
    }

    .find .parentcardss {
        grid-template-columns: 1fr !important;
    }

    .videos .upperheadingvideo .left h2 {
        font-size: 26px;
    }

    .videos .upperheadingvideo {
        flex-direction: column;
        align-items: flex-start;

    }

    .videos .upperheadingvideo .right {
        width: 100%;

    }

    .videos .upperheadingvideo .right .twisebutton {
        margin-top: 15px;
    }

    .videos .parentsssss {
        flex-direction: column;
        align-items: flex-start;
    }

    .videos .parentsssss .left .watchbutton button {
        max-width: 100%;
    }

    .videos .parentsssss .rig .textttttttttttttttt .innerttext p {
        max-width: 100%;
    }

    .videos .parentsssss .rig .textttttttttttttttt .innerttext button {
        max-width: 100%;
    }
}