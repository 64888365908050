.applyforvion {
    .top-head {
        position: relative;
        padding-top: 132px;
        min-height: 326px;
        overflow: hidden;

        .head-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
            z-index: -1;
            pointer-events: none;
        }

        h6 {
            color: #FFF;
            font-size: 62px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;

            span {
                color: #2DD98F;
            }
        }
    }
}

.applyforvion-section {
    padding-top: 120px;
    padding-bottom: 156px;
    position: relative;

    .applyforvion-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        pointer-events: none;
    }

    .applyforvion-bottom {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        pointer-events: none;
        z-index: -1;
    }
}


.parent-applicationform {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;

    .left-side {
        max-width: 271px;
        width: 100%;
        position: sticky;
        top: 30px;
        border: 1px solid rgba(255, 255, 255, 0.07);
        background: rgba(0, 0, 0, 0.80);
        padding: 20px;

        .selected-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 40px;

            .single-content {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 15px;
                cursor: pointer;

                .num-card {
                    border: 1px solid #121212;
                    background: transparent;
                    padding: 17px 0;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        color: #939393;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        letter-spacing: -0.022px;
                        text-transform: uppercase;
                    }

                    &.active {
                        border: 1px solid #2DD98F;
                        background: transparent;

                        p {
                            color: #fff;
                        }
                    }


                }

                .text-side {
                    h6 {
                        color: #fff;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        letter-spacing: -0.018px;
                        text-transform: uppercase;

                        &.active {
                            font-weight: 700;

                        }
                    }
                }
            }
        }
    }

    .right-side {
        flex: 1;
    }
}


.common-box-style {
    border: 2px solid rgba(255, 255, 255, 0.07);
    background: rgba(0, 0, 0, 0.80);
    padding: 30px;

    .main-heading {
        margin-bottom: 35px;

        h6 {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.018px;
            text-transform: uppercase;
        }
    }

    .option-field {
        margin-bottom: 30px;

        .material-textfield {
            position: relative;

            label {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                display: block;
                margin-bottom: 15px;
                text-transform: capitalize;
                background-color: transparent !important;
            }

            input {
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.07);
                padding: 17px 6px 17px 18px;
                width: 100%;
                color: #2DD98F;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%;
                transition: 0.3s linear;
                outline: none !important;

                &::placeholder {
                    color: #3B3B3B;
                }

                &.active {
                    border: 1px solid #2DD98F;
                }
            }

            textarea {
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.07);
                padding: 17px 6px 17px 18px;
                width: 100%;
                color: #2DD98F;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%;
                transition: 0.3s linear;
                outline: none !important;
                min-height: 110px;

                &::placeholder {
                    color: #3B3B3B;
                }

                &.active {
                    border: 1px solid #2DD98F;
                }
            }

            .calendar-icon {
                position: absolute;
                top: 68%;
                right: 0;
                transform: translate(-50%, -50%);
                pointer-events: none;

            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.iferrorinput {
            .material-textfield label {
                color: #E82D2D !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
            }

            .material-textfield input {
                border: 1px solid #E82D2D;
            }

            .error-text {
                color: #E82D2D;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%;
                margin-top: 8px;
            }
        }

        .showing-text {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 18px;

            span {
                font-weight: 700;
            }
        }

        .dropdown {
            .dropdown-toggle {
                border: 1px solid rgba(255, 255, 255, 0.07);
                background: transparent;
                padding: 20px 18px;
                color: #7E7E7E;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                label {
                    background-color: transparent !important;
                    pointer-events: none;
                    color: #fff !important;

                }

                &::after {
                    display: none;
                }

                &[aria-expanded="true"] {
                    border: 1px solid #2DD98F;

                    svg {
                        transform: rotate(180deg);
                    }


                }
            }

            .dropdown-menu {
                width: 100%;
                background: #000;
                border: 1px solid rgba(255, 255, 255, 0.07);
                padding: 0;
                border-radius: 0;

                a {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
                    padding: 15px;
                    color: #fff;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    white-space: break-spaces;
                }
            }

            // .selected-categroy {
            //     top: 0 !important;
            //     left: 6px !important;
            //     transform: translateY(-50%) scale(0.9) !important;
            //     padding: 0 0.3rem !important;
            //     font-weight: 700 !important;
            //     background: #fff !important;
            //     background-color: #fff !important;
            //     text-transform: capitalize;
            //     color: #000 !important;
            // }
        }
    }

    .bottom-btns {
        margin-top: 60px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 18px;

        .btn-nextt {
            max-width: 200px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #666667;
            opacity: 0.4;
            background: transparent;
            padding: 0;


            h6 {
                border: 1px solid #666667;
                background: transparent;
                padding: 15px 12px;
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                flex: 1;
            }

            svg {
                width: 57px;
            }
        }

        .btn-backk {
            max-width: 200px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #666667;
            background: transparent;
            padding: 0;


            h6 {
                border: 1px solid #666667;
                background: transparent;
                padding: 15px 12px;
                color: #fff;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                flex: 1;
            }

            svg {
                width: 57px;
            }
        }

        .btn-blackk {
            max-width: 200px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #666667;
            background: transparent;
            padding: 0;

            h6 {
                border: 1px solid #666667;
                background: transparent;
                padding: 15px 12px;
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                flex: 1;
            }

            svg {
                width: 57px;
            }
        }
    }
}


.contact-detail {
    .kyc-parent {
        .head-text {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.018px;
            text-transform: uppercase;
            margin-bottom: 35px;
        }

        .kyc-box {
            border: 1px solid rgba(255, 255, 255, 0.07);
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 18px;

            h6 {
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
            }

            .btn-startkyc {
                border: 1px solid #0B0B0B;
                background: #2DD98F;
                box-shadow: 2px 2px 0px 0px #000;
                padding: 8px 18px;
                color: #0B0B0B;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
            }

            .btn-upload {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .after-upload {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .left-side {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;

                    h6 {
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                    }
                }

                .right-side {
                    flex: none;
                }
            }
        }
    }

    .bottom-content {
        .error-text {
            color: #E82D2D;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            margin-top: 8px;
        }
    }
}

.project-overview {

    .team-information {
        margin-top: 35px;

        .head-text {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.018px;
            text-transform: uppercase;
            margin-bottom: 25px;
        }
    }

    .key-teammembers {
        margin-top: 30px;

        .head-text {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            margin-bottom: 16px;
        }

        .single-keymember {
            background: #060606;
            padding: 20px;

            h6 {
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.014px;
                text-transform: uppercase;
                margin-bottom: 35px;
            }

            .material-textfield input:focus+label {
                background-color: #F9F9F9 !important;
            }

            .material-textfield textarea:focus+label {
                background-color: #F9F9F9 !important;
            }

            .material-textfield input:not(:placeholder-shown)+label,
            .material-textfield textarea:not(:placeholder-shown)+label {
                background-color: #F9F9F9 !important;
            }
        }

        .btn-remove {
            border: 1px solid #F00;
            background: transparent;
            padding: 8px 18px;
            color: #F00;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            display: block;
            margin-left: auto;
        }

        .btn-another {
            border: 1px solid #FFF;
            background: #060607;
            padding: 8px 18px;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            margin-top: 18px;
            display: block;
            margin-left: auto;
            margin-bottom: 30px;
        }
    }

    .technical-details {
        .head-text {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.018px;
            text-transform: uppercase;
            margin: 35px 0;
        }

        .option-field {
            h6 {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                margin-bottom: 18px;
            }
        }
    }
}


.finalize-project {
    .custom-checkbox {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 25px;


        &:last-child {
            margin-bottom: 0;
        }

        label {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        a {
            color: #2DD98F;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: underline !important;
            text-transform: uppercase;
        }
    }

    .error-text {
        margin-top: -15px;
        margin-bottom: 25px;
    }
}





@media (max-width:1024px) {
    .parent-applicationform {
        flex-direction: column;
    }

    .parent-applicationform .left-side {
        max-width: 100%;
        position: static;
    }

    .parent-applicationform .left-side .selected-content {
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: auto;
        padding: 20px 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .parent-applicationform .left-side .selected-content .single-content .text-side h6 {
        font-size: 16px;
    }

    .parent-applicationform .right-side {
        flex: auto;
        width: 100%;
    }

    .common-box-style {
        padding: 30px 20px;
    }

    .applyforvion .techbannerinner .techhead .dnoneonmobile {
        display: none;
    }

    .common-box-style .bottom-btns {
        gap: 10px;
    }



}



.applicationsubmit-modal {
    .modal-dialog {
        max-width: 568px;
    }

    .modal-header .modal-title {
        text-transform: none;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: capitalize;
    }

    .modal-body p {
        max-width: 388px;
        width: 100%;
        margin: 0 auto;
    }
}


@media (max-width:600px) {
    .applyforvion .top-head .head-bg {
        height: 100%;
        object-position: right;
    }

    .applyforvion .top-head h6 {
        font-size: 34px;
    }

    .applyforvion .top-head {
        padding-top: 70px;
    }

    .applyforvion-section {
        padding-top: 60px;
        padding-bottom: 120px;
    }
}



.contact-modal {
    background: rgba(6, 4, 32, 0.50);
backdrop-filter: blur(6.5px);
    .modal-dialog {
        max-width: 524px;
    }

    .modal-content {
        border: 1px solid #2DD98F;
        background: #000;
        padding: 32px;
        background: url("../../assets/modal-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .modal-body {
        padding: 0;

        img {
            display: block;
            margin: 0 auto;
        }

        h6 {
            color: #FFF;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 900;
            line-height: 120%;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }

        .btn-okay {
            border: 0px solid #2DD98F;
            background: #2DD98F;
            padding: 18px 16px;
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
        }
    }
}

@media (max-width:600px) {
    .contact-modal .modal-body h6 {
        font-size: 26px;
    }
}