.main-navbar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.00);
    backdrop-filter: blur(10px);
    min-height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 999;

    .border-bottom-line {
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, black 10%, #5C5C5C 50%, black 100%);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: -1;

    }

    .navbar-brand {
        margin: 0;
    }

    .navbar-nav {
        display: flex;
        align-items: center;
        gap: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .nav-item {


            .maindrop {
                .dropdown-toggle::after {
                    display: none;
                }

                .dropdown {
                    .dropdown-toggle {
                        background: transparent !important;
                        border: none !important;
                        color: #BDBDBD;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &[aria-expanded="true"] {
                            color: #2DD98F;

                            svg {
                                transform: rotate(180deg);

                                path {
                                    stroke: #2DD98F;
                                }
                            }
                        }
                    }

                    .dropdown-menu {
                        border-radius: 5px;
                        background: #141414;
                        padding: 8px;
                        width: 300px;



                        .box {
                            border-radius: 5px;
                            border: 1px solid #1B1B1B;

                            background: #141414;
                            padding: 14px;
                            width: 100%;
                            margin-bottom: 5px;

                            &:last-child {
                                margin-bottom: 0px;
                            }

                            .parentbox {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .left {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    gap: 10px;

                                    .innerleft {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        gap: 10px;

                                        .innerright {
                                            h3 {
                                                color: #FFF;
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 120%;
                                                transition: 0.3s linear;

                                            }


                                            p {
                                                color: #717171;

                                                margin-top: 4px;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 120%;
                                            }
                                        }
                                    }
                                }

                            }

                            svg {
                                path {
                                    transition: 0.3s linear;
                                }
                            }

                            &:hover {
                                border-radius: 5px;
                                background: #1D1D1D;

                                h3 {
                                    color: #2DD98F !important;
                                }

                                .forhov {
                                    path {
                                        stroke: #fff;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .nav-link {
                padding: 0;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;

                &.active {
                    color: #2DD98F !important;
                }

                &:hover {
                    color: #2DD98F !important;
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .main-navbar .navbar-nav {
        position: static;
        transform: unset;
        padding: 20px 0;
    }

    .main-navbar .hover-button {
        justify-content: space-between;
    }

    .main-navbar {
        min-height: 100%;
        height: auto;
        padding: 10px 0;
    }
}

.mobile-offcanvas {
    background-color: #000;
    min-width: 100%;

    .nav-item {


        .maindrop {
            .dropdown-toggle::after {
                display: none;
            }

            .dropdown {
                .dropdown-toggle {
                    background: transparent !important;
                    border: none !important;
                    color: #BDBDBD;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 22px 10px;
                    width: 100%;

                    &[aria-expanded="true"] {
                        color: #2DD98F;

                        svg {
                            transform: rotate(180deg);

                            path {
                                stroke: #2DD98F;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    border-radius: 5px;
                    background: #141414;
                    padding: 8px;
                    width: 100%;



                    .box {
                        border-radius: 5px;
                        border: 1px solid #1B1B1B;

                        background: #141414;
                        padding: 14px;
                        width: 100%;
                        margin-bottom: 5px;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .parentbox {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .left {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 10px;

                                .innerleft {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    gap: 10px;

                                    .innerright {
                                        h3 {
                                            color: #FFF;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 120%;
                                            transition: 0.3s linear;

                                        }


                                        p {
                                            color: #717171;

                                            margin-top: 4px;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 120%;
                                        }
                                    }
                                }
                            }

                        }

                        svg {
                            path {
                                transition: 0.3s linear;
                            }
                        }

                        &:hover {
                            border-radius: 5px;
                            background: #1D1D1D;

                            h3 {
                                color: #2DD98F !important;
                            }

                            .forhov {
                                path {
                                    stroke: #fff;
                                }
                            }
                        }

                    }
                }
            }
        }

        .nav-link {
            padding: 0;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;

            &.active {
                color: #2DD98F !important;
            }

            &:hover {
                color: #2DD98F !important;
            }
        }
    }

    // ul {
    //     li {
    //         a {
    //             border-top: 1px solid #111;
    //             border-bottom: 1px solid #111;
    //             padding: 22px 10px;
    //             color: #FFF;
    //             font-size: 20px;
    //             font-style: normal;
    //             font-weight: 500;
    //             line-height: 120%;

    //             &.active {
    //                 color: #2DD98F !important;
    //             }

    //             &:hover {
    //                 color: #2DD98F !important;
    //             }
    //         }
    //     }
    // }

    .transparent-btn {
        justify-content: space-between;
        padding: 20px;
    }

    .btn-close {
        background: url("../../../assets/close-icon.svg") !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position: center;
        opacity: 1;
        box-shadow: none;
        padding: 2px;
    }
}