.Communityevents {
    background: #000;
    padding-top: 40px;
    padding-bottom: 95px;
    position: relative;

    .parentcommunity {
        display: flex;
        // align-items: center;
        justify-content: flex-start;
        gap: 45px;

        .leftparentssss {
            position: sticky;
            top: 20px;
            height: 500px;

            .maineventimg {
                width: 371.363px;
                height: 338px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;
                flex-shrink: 0;

                .imagess {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .grey {
                color: #777;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-top: 25px;
                margin-bottom: 8px;
            }

            .white {
                color: #E4E4E4;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }
        }

        .right {
            .mainhead {
                color: #FFF;
                font-size: 46px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                margin-bottom: 35px;
            }

            .parentss {
                .innerparentssss {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                    margin-bottom: 24px;

                    .left {
                        .innerleftimg {
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                            object-position: center;
                            overflow: hidden;

                            .imagess {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }

                    .right {
                        h6 {
                            color: #FFF;
                            margin-bottom: 6px;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 120%;
                        }

                        p {
                            color: #8B8B8B;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                        }
                    }
                }
            }

            .registration {
                background: #0F0F0F;
                padding: 18px;
                width: 100%;

                p {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                }
            }

            .approval {
                border-bottom: 1px solid #0F0F0F;

                background: #000;
                padding: 18px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;

                .mainimg {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    object-position: center;
                    overflow: hidden;

                    .inner {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .content {
                    h6 {
                        color: #FFF;
                        margin-bottom: 6px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%;
                    }

                    p {
                        color: #8B8B8B;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }
                }
            }

            .eventsss {
                margin: 32px 0px;

                h3 {
                    color: #2DD98F;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    margin-bottom: 18px;
                }

                p {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0px !important;
                    }
                }
            }

            .lastpara {
                h3 {
                    color: #2DD98F;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    margin-bottom: 18px;
                }

                ul {
                    list-style-type: disc;
                    list-style-position: outside;
                    margin-left: 20px;

                    li {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:1050px) {
    .Communityevents .parentcommunity {
        flex-direction: column;
    }

    .Communityevents .parentcommunity .left .maineventimg {
        width: 100%;
        height: 100%;
    }
}

@media(max-width:600px) {
    .Communityevents .parentcommunity .right .mainhead {
        font-size: 28px;
    }

    .Communityevents .parentcommunity .right .eventsss p {
        font-size: 14px;
    }

    .Communityevents .parentcommunity .right .lastpara ul li {
        font-size: 14px;
    }

    .Communityevents .parentcommunity .leftparentssss {
        position: static;
        height: auto;
    }
    .Communityevents .parentcommunity .leftparentssss .maineventimg{
        width: 100%;
    }
}