.vioon-section {
    overflow: hidden;

    .imgelip77777 {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
    }

    .part1_section {

        .imgbackground {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-position: center;

            z-index: -1;
        }

        .part1 {
            position: relative;

            .imgcicleeee {
                padding-top: 200px;
            }


            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 60px;

            .lineelip {
                position: absolute;
                pointer-events: none;
                /* Position the image absolutely */
                left: 50%;
                /* Position it 50% from the left */
                /* Position it 50% from the top */
                transform: translate(-50%, -50%);
                /* Move it back by half its width and height */
                /* Ensure the image is responsive */
                height: auto;
                /* Maintain aspect ratio */
            }

            .left_side {
                padding-top: 90px;

                h1 {
                    color: #2DD98F;

                    font-size: 62px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    /* 62px */
                    text-transform: uppercase;
                }

                h2 {
                    color: #FFF;

                    font-size: 62px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    text-transform: uppercase;
                }

                p {
                    color: #FFF;
                    max-width: 438px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 16.8px */
                    padding-top: 30px;
                    padding-bottom: 57px;
                }

                button {
                    color: #00110A;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 15px 15px 15px 30px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    background: #2DD98F;
                    border: unset;
                }
            }

            .right_side {

                padding-top: 74px;

                .imgelip {
                    position: absolute;
                    right: 0px;
                    height: 600px;
                }
            }
        }
    }

    .part2_section {
        position: relative;

        .imgelip22 {
            position: absolute;
            width: 100%;
            z-index: -1;
        }

        .part2 {
            padding-bottom: 50PX;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left_side {

                width: 532px;

                span {
                    color: #2DD98F !important;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 120%;
                    text-transform: uppercase;
                }

                h1 {
                    color: #2DD98F;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 100%;
                    /* 62px */
                    text-transform: uppercase;
                }

                h2 {
                    color: #FFF;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 120%;
                    text-transform: uppercase;

                }

                p {
                    color: #FFF;
                    max-width: 438px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 16.8px */
                    padding-top: 20px;
                }

                h3 {
                    color: rgba(255, 255, 255, 0.50);
                    padding-bottom: 14px;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                }

                button {
                    color: #00110A;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 15px 15px 15px 30px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    margin-top: 49px;
                    border: unset;
                }
            }

            .right_side {

                .imgelip2 {
                    position: absolute;
                    width: 100%;
                    z-index: -1;
                    right: 0px;
                    left: 0px;
                    top: 648px;
                }
            }
        }
    }

    .part3_section {
        position: relative;

        .lineelip {
            position: absolute;
            pointer-events: none;
            top: 0px;
            width: 100%;
        }

        .imgelip22 {
            position: absolute;
            width: 100%;
            z-index: -1;
        }

        .lastelip {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            max-width: 100%;
            height: auto;
        }

        .part3 {
            position: relative;
            padding-top: 63px;
            padding-bottom: 115px;

            h6 {
                color: #FFF;
                padding-top: 54px;
                padding-bottom: 20px;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 20px */
                text-transform: uppercase;
            }

            h1 {
                color: #FFF;
                font-size: 42px;
                padding-bottom: 60px;
                padding-top: 54PX;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 50.4px */
                text-transform: uppercase;
            }

            h2 {
                color: #FFF;

                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 22px */
                text-transform: uppercase;
            }

            p {
                color: #BDBDBD;
                font-size: 18px;

                padding-bottom: 7px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 21.6px */
            }

            button {
                color: #FFF;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                /* 14.4px */
                text-transform: uppercase;
                display: flex;
                padding: 12px 15px 12px 25px;
                justify-content: center;
                align-items: center;
                gap: 15px;
                border: 1px solid #FFF;
                background-color: unset;
            }

            .card1 {
                height: 295px;
                padding: 47px 29px;
                position: relative;
                border: 1px solid #1F1F1F;

                // background: url(../../assets/Rectangle.png) lightgray 0% 0% / 100px 100px repeat;
            }

            .boxxcard {
                position: absolute;
                /* Set absolute positioning for the image */
                top: 0;
                /* Adjust vertical position */
                left: 0;
                /* Adjust horizontal position */
                width: 100%;
                /* Make the image fill the container */
                height: 100%;
                /* Make the image fill the container */
                // opacity: 0.5;
                /* Set image opacity to make it more transparent */
                z-index: 0;
                /* Set a lower z-index for the image */
                object-fit: cover;
                /* Scale the image while preserving aspect ratio */
            }

            .inner_part3 {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;
            }

            .card2 {
                padding: 30px;
                // background-image: url(../../assets/twocard.png);
                border: 1px solid #1F1F1F;
                position: relative;

                .imgcarddd {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    object-fit: cover;
                    z-index: -1;
                    pointer-events: none;
                }

                h1 {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    /* 20px */
                    text-transform: uppercase;
                }

                p {
                    color: #BDBDBD;


                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 19.2px */
                }

                button {
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 12px 15px 12px 25px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    background-color: unset;
                    cursor: pointer;
                    border: 1px solid #FFF;
                }
            }

            .card2:hover {
                border: 1px solid #2DD98F;

            }

            .innersection {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                padding-top: 20px;
            }
        }

    }

    .part11_section {
        position: relative;

        .imgbackground {
            position: absolute;
            width: 100%;
            z-index: -1;
        }

        .lineelip {
            position: absolute;
            pointer-events: none;
            width: 100%;
        }

        .imgelip {
            position: absolute;
            right: 0px;
        }

        .part11 {
            display: flex;
            align-items: center;
            justify-content: space-between;
padding-bottom: 100px;
            .left_side {
              


                h1 {
                    color: #FFF;

                    font-size: 40px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 120%;
                    text-transform: uppercase;
                }

                p {
                    color: #FFF;
                    max-width: 438px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 16.8px */
                    padding-top: 30px;

                }

                button {
                    color: #00110A;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    text-transform: uppercase;
                    display: flex;
                    padding: 15px 15px 15px 30px;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    background: #2DD98F;
                    border: unset;
                }
            }

            .right_side {
                padding-top: 169px;
                flex-shrink: 0;

            }
        }
    }


}

@media (max-width: 1024px) {

    .vioon-section .part1 {
        flex-direction: column !important;
    }

    .vioon-section .part3_section .part3 h1 {
        padding-bottom: 30px;
    }

    .vioon-section .part1_section .part1 .imgcicleeee {
        /* padding-top: 200px; */
        position: absolute;
        width: 160px;
        bottom: 12px;
    }

    .vioon-section .part3 .innersection {
        display: grid;
        grid-template-columns: 1fr !important;
        gap: 20px;
        padding-top: 20px;
    }

    .vioon-section .part11 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding-bottom: 15px !important;
    }

    .vioon-section .part1 .left_side h2 {
        font-size: 30px !important;
    }

    .vioon-section .part1 .left_side h1 {
        font-size: 30px !important;
    }

    .vioon-section .part2 {
        flex-direction: column-reverse;
        margin-top: 0px;
        padding-bottom: 0px !important;
    }

    .vioon-section .part3_section .part3 {
        position: relative;
        padding-top: 63px;
        padding-bottom: 37px;
    }

    .vioon-section .part2 .left_side {
        width: 100% !important;
    }

    .vioon-section .part2 .right_side .imgelip2 {
        position: absolute;
        width: 100%;
        z-index: -1;
        right: 0px;
        left: 0px;
        top: 796px;
    }

    .vioon-section .part1 .right_side .imgelip {
        position: absolute;
        right: 0px;
        height: 905px;
    }

    .vioon-section .part3 h1 {
        color: #FFF;
        font-size: 32px !important;
        padding-bottom: 62px;
        font-style: normal;
        // text-align: center;
        font-weight: 700;
        line-height: 120%;
        padding-top: 0px !important;
        text-transform: uppercase;
    }
    .vioon-section .part11_section .part11 .left_side h1 {
        color: #FFF;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        text-transform: uppercase;
    }
    .vioon-section .part11_section .part11 .right_side {
        padding-top: 71px;
        flex-shrink: 0;
    }
    .vioon-section .part3 .inner_part3 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .vioon-section .part4 h1 {
        color: #FFF;
        padding-bottom: 30px;
        font-size: 33px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }

    .vioon-section .part5 .innersection {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding-top: 20px;
    }
}

.wrapper-viondex {
    .main-navbar {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background-color: transparent;
        backdrop-filter: blur(0);
    }

    .main-navbar .border-bottom-line {
        background: linear-gradient(to right, transparent 10%, #5C5C5C 50%, transparent 100%);
    }
}

@media (max-width:1100px) {
    .wrapper-viondex .main-navbar {
        position: static;
    }

}