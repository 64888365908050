.roadmap-section {
  ul {
    list-style-type: disc; // Ensure bullet points are shown
    padding-left: 20px; // Add some space for bullets
    margin: 0;
  }
  li {
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.8);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  .bottom-roadmap {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .left-heading {
      .style1 {
        margin-bottom: 166px;
      }
      .style2 {
        margin-bottom: 114px;
      }
      .style3 {
        margin-bottom: 163px;
      }
      .style4 {
        margin-bottom: 195px;
      }
      .style5 {
        margin-bottom: 185px;
      }
      .style6 {
        margin-bottom: 114px;
      }

      .common-heading-style {
        // margin-bottom: 170px;

        p {
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 1.2px;
          text-transform: uppercase;
          margin-bottom: 14px;
          text-align: end;
        }

        h4 {
          color: #fff;
          font-size: 28px;
          font-style: normal;
          font-weight: 900;
          line-height: 100%;
          text-transform: uppercase;
        }
      }
    }

    .middle-line {
      margin-left: 71px;
      margin-right: 51px;
    }

    .right-detail {
      max-width: 351px;
      width: 100%;
      .single-detail.phase1 {
        margin-bottom: 40px;
      }
      .single-detail.phase2 {
        margin-bottom: 40px;
      }
      .single-detail.phase3 {
        margin-bottom: 67px;
      }
      .single-detail.phase4 {
        margin-bottom: 40px;
      }
      .single-detail.phase5 {
        margin-bottom: 69px;
      }
      .single-detail.phase6 {
        margin-bottom: 40px;
    }
      .single-detail {
        // margin-bottom: 40px;

        p {
          color: rgba(255, 255, 255, 0.8);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }
  }

  .bottom-roadmap-mobile {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 9px;
      height: 100%;
      width: 2px;
      background: #002cff;
    }

    .single-content {
      display: flex;
      position: relative;
      z-index: 1;
      gap: 20px;
      margin-bottom: 50px;

      .left-img {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-shrink: 0;
      }
      h6 {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        margin-bottom: 14px;
      }

      h4 {
        color: #fff;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        text-transform: uppercase;
      }

      p {
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 25px;
      }
    }
  }
}

@media (max-width: 600px) {
    .bottom-roadmap-mobile .single-content h4 {
       
        padding-bottom: 15px;
    }
  .roadmap-section .bottom-roadmap {
    display: none;
  }
  .roadmap-section .bottom-roadmap-mobile {
    display: block !important;
  }
}

@media (max-width: 400px) {
  .roadmap-section .main-heading h4 {
    font-size: 24px;
  }
}
