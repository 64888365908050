.vion-grant {
    .top-head {
        position: relative;
        padding-top: 132px;
        min-height: 326px;
        overflow: hidden;

        .head-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
            z-index: -1;
            pointer-events: none;
        }

        h6 {
            color: #FFF;
            font-size: 62px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;

            span {
                color: #2DD98F;
            }
        }
    }

    .first-section {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 132px 0;

        .firstsectionbg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            pointer-events: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .parent {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-side {
                max-width: 508px;
                width: 100%;

                .main-content {
                    h6 {
                        color: #FFF;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        text-transform: uppercase;

                        span {
                            color: #2DD98F;
                        }
                    }

                    p {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        margin: 49px 0;
                    }

                    .btn-apply {
                        background: #FFF;
                        padding: 15px 15px 15px 30px;
                        color: #00110A;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 15px;
                        max-width: 127px;
                        width: 100%;
                    }
                }
            }

            .right-side {
                flex: 1;
            }
        }
    }

    .sec-section {
        position: relative;
        padding-top: 94px;
        padding-bottom: 114px;

        .feature-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            pointer-events: none;
        }

        .main-heading {
            margin-bottom: 64px;

            h6 {
                color: #FFF;
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
            }
        }

        .bottom-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px 20px;

            .single-card {
                border: 1px solid rgba(255, 255, 255, 0.07);
                background: rgba(0, 0, 0, 0.80);
                padding: 30px 30px 45px 30px;
                transition: 0.3s linear;
                position: relative;
                z-index: 9;

                .feature-cardbg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: -1;
                    pointer-events: none;
                }

                &:hover {
                    border: 1px solid #2DD98F;
                    background: rgba(0, 0, 0, 0.80);
                }

                h6 {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }

                p {
                    color: #BDBDBD;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }
    }

    .third-sec {
        position: relative;
        padding-top: 79px;

        .application-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            pointer-events: none;
        }

        .main-heading {
            margin-bottom: 100px;

            h4 {
                color: #FFF;
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                text-align: center;
            }
        }

        .bottom-roadmap {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            .left-heading {
                .common-heading-style {
                    margin-bottom: 200px;

                    p {
                        color: rgba(255, 255, 255, 0.50);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        letter-spacing: 1.2px;
                        text-transform: uppercase;
                        margin-bottom: 14px;
                        text-align: end;
                    }

                    h4 {
                        color: #FFF;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 100%;
                        text-transform: uppercase;
                    }
                }
            }

            .middle-line {
                margin-left: 71px;
                margin-right: 51px;
                margin-top: -18px;
            }

            .right-detail {
                max-width: 351px;
                width: 100%;

                .single-detail {
                    margin-bottom: 72px;

                    p {
                        color: rgba(255, 255, 255, 0.80);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }
                }

            }

        }

        .bottom-roadmap-mobile {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0px;
                left: 19px;
                height: 100%;
                width: 2px;
                background: #002CFF;
            }

            .single-content {
                display: flex;
                position: relative;
                z-index: 1;
                gap: 20px;
                margin-bottom: 50px;

                .left-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-shrink: 0;
                }

                h6 {
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                    margin-bottom: 14px;
                }

                h4 {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    text-transform: uppercase;
                }

                p {
                    color: rgba(255, 255, 255, 0.80);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-top: 25px;
                }
            }


        }
    }

    .fourth-section {
        position: relative;
        padding-top: 90px;
        padding-bottom: 70px;

        .support-bg {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0%);
            z-index: -2;
            pointer-events: none;
            object-fit: cover;
        }

        .main-heading {
            h6 {
                color: #FFF;
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                margin-bottom: 30px;
            }

            p {
                color: #BDBDBD;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }

        .bottom-content {
            margin: 24px 0;

            .single-text {
                border: 1px solid #1F1F1F;
                background: #000;
                padding: 20px 15px;
                margin-bottom: 15px;

                h6 {
                    color: #BDBDBD;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;

                    span {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }
            }
        }

        .para {
            color: #BDBDBD;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-top: 8px;
        }
    }
}





@media (max-width: 600px) {
    .third-sec .bottom-roadmap {
        display: none !important;
    }

    .third-sec .bottom-roadmap-mobile {
        display: block !important;
    }

    .vion-grant .top-head .head-bg {
        height: 100%;
        object-position: right;
    }
    .vion-grant .top-head h6{
        font-size: 36px;
    }
    .vion-grant .sec-section .bottom-content{
        grid-template-columns: 1fr;
    }
    .vion-grant .first-section .firstsectionbg{
        height: 100%;
    }
    .vion-grant .first-section .parent{
        flex-direction: column;
    }
    .vion-grant .first-section{
        padding: 60px 0;
    }
    .vion-grant .first-section .parent .left-side .main-content h6{
        font-size: 26px;
    }
    .vion-grant .first-section .parent .left-side .main-content p{
        font-size: 16px;
    }
    .vion-grant .sec-section{
        padding: 60px 0;
    }
    .vion-grant .sec-section .main-heading h6{
        font-size: 26px;
        text-align: center;
    }
    .vion-grant .sec-section .bottom-content .single-card{
        padding: 25px 15px;
    }
    .vion-grant .sec-section .bottom-content .single-card h6{
        font-size: 18px;
    }
    .vion-grant .third-sec .main-heading h4{
        font-size: 26px;
    }
    .vion-grant .fourth-section .main-heading h6{
        font-size: 26px;
    }
    .vion-grant .top-head{
        padding-top: 70px;
    }
    .vion-grant .fourth-section{
        padding: 60px 0;
    }
    .vion-grant .third-sec{
        padding: 60px 0;
    }
}

@media (max-width:400px) {
    .third-sec .main-heading h4 {
        font-size: 24px;
    }
}