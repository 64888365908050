.build-section{
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    overflow: hidden;
    .build-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
    }
    .main-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        h4{
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; 
            text-transform: uppercase;
            max-width: 680px;
            width: 100%;
        }
    }
}

@media (max-width:1024px){
    .build-section .build-bg{
        height: 100%;
    }
    .build-section .main-content{
        flex-direction: column;
        gap: 25px;
    }
    .build-section .main-content h4{
        font-size: 24px;
        text-align: center;
    }
}