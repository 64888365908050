@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Jost:ital,wght@0,100..900;1,100..900&family=Mada:wght@200..900&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  background: #000;
  min-height: 100vh;
  font-family: "Inter", sans-serif;
}

.not_clickable{
  pointer-events: none !important;
}
.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}



.cursor-pointer {
  cursor: pointer;
}


iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

input[type="date"] {
  text-transform: uppercase;
}


.custom-container {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.custom-containermain{
  max-width: 1198px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1600px) and (min-height: 900px) {
  .custom-container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}



.transparent-btn {
  border-radius: 0px;
  border: 1px solid #26C782;
  background: #000;
  // padding: 12px 15px 12px 25px;
  padding: 12px 25px;
  color: #26C782;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 15px;
}

.green-btn {
  border-radius: 0px;
  background: #2DD98F;
  padding: 15px;
  color: #00110A;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 15px;

}


.white-btn {
  border-radius: 0px;
  background: #FFF;
  padding: 15px 15px 15px 30px;
  color: #00110A;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 15px;

}




// mouse hover effect/...............................

// .shadow-circle {
//   position: absolute;
//   width: 150px;
//   height: 150px;
//   background: rgba(255, 255, 255, 0.54);
//   border-radius: 50%;
//   pointer-events: none;
//   transform: translate(-50%, -50%);
//   opacity: 0;
//   transition: opacity 0.2s ease;
//   box-shadow: 0 0 143px 110px rgba(255, 255, 255, 0.6);
//   z-index: 9999;
// }


// .shadow-circle {
//   position: absolute;
//   background: transparent; 
//   border-radius: 50%;
//   pointer-events: none;
//   transform: translate(-50%, -50%);
//   opacity: 0;
//   transition: opacity 0.2s ease;
//   box-shadow: 
//     0 0 80px 40px rgba(0, 255, 0, 0.5), 
//     0 0 120px 80px rgba(0, 0, 255, 0.5),  
//     0 0 150px 100px rgba(255, 255, 255, 0.3); 
//   // z-index: 9999;
// }


// upgraded ...................
.shadow-circle {
  position: absolute;
  background: transparent;
  // border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  opacity: 1;
  /* Keep opacity at 1 */
  transition: opacity 1s ease;
  /* Adjust transition for opacity */
  // box-shadow:
  //   0 0 80px 40px rgba(0, 255, 0, 0.5),
  //   0 0 120px 80px rgba(0, 255, 0, 0.5),
  //   0 0 150px 100px rgba(0, 255, 0, 0.5);
  box-shadow:
    0 0 200px 200px rgba(43, 255, 0, 0.15),
    0 0 200px 200px rgba(43, 255, 0, 0.15),
    0 0 200px 200px rgba(43, 255, 0, 0.15);
  z-index: 999999;
}



// button hover effect .............................

.hover-button {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}


.button-text,
.arrow {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.button-text {
  opacity: 1;
  margin-left: 15px;
}

.arrow {
  opacity: 1;
}


.new-button-text,
.new-arrow {
  position: absolute;
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}


.new-button-text {
  transform: translateY(10px);
  bottom: 10px;
  margin-left: 15px;

}

.new-arrow {
  transform: translateX(-10px);
  right: 15px;
}

.hover-button:hover .button-text {
  transform: translateY(-100%);
  opacity: 0;
}

.hover-button:hover .arrow {
  transform: translateX(100%);
  opacity: 0;
}


.hover-button:hover .new-button-text {
  transform: translateY(0);
  opacity: 1;
  margin-bottom: 4px;
}

.hover-button:hover .new-arrow {
  transform: translateX(0);
  opacity: 1;
}

.green-btn-mbl {
  border: 1px solid #26C782;
  background: #000;
  padding: 20px 0px;
  color: #26C782 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 15px;
}


.custom-checkbox {
  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #121212;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked+label:before {
    content: '';
    background-color: #2DD98F;
  }

  .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

