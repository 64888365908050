.new-universe {
    padding-top: 120px;
    position: relative;
    z-index: 99;

    .bg-section {
        position: absolute;
        top: 120px;
        left: 0;
        width: 100%;
        pointer-events: none;
        z-index: -5;
    }

    .top-center-shadow {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -10%);
        z-index: -2;
        pointer-events: none;
    }

    .parent {
        background: rgba(0, 0, 0, 0.95);
        backdrop-filter: blur(5px);
        position: relative;
        z-index: 9;
        padding-bottom: 110px;
        overflow: hidden;

        .bg-effect-dim {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 0;
        }

        .left-top-shadow {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            z-index: -1;
            pointer-events: none;
        }

        .right-bottom-blue {
            position: absolute;
            bottom: 0;
            right: 0;
            pointer-events: none;
            z-index: -1;
            pointer-events: none;
        }

        .percent-img {
            position: absolute;
            bottom: 0;
            right: 0;
            pointer-events: none;
            z-index: 0;
            pointer-events: none;
        }

        .main-content {
            max-width: 740px;
            width: 100%;
            padding-left: 65px;
            padding-top: 60px;
            position: relative;

            .top-text {
                color: rgba(255, 255, 255, 0.50);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                margin-bottom: 14px;
            }

            h4 {
                color: #FFF;
                font-size: 50px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                text-transform: uppercase;
            }

            .para {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-top: 30px;
            }

            .price-div {
                margin-top: 35px;

                p {
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    margin-bottom: 10px;
                }

                h5 {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 100%;
                    text-transform: uppercase;
                   
                }
            }

            .white-btn {
                max-width: 190px;
                width: 100%;
                margin-top: 35px;
            }
        }
    }
}

.onlyforwithhhf{

    max-width: 260px !important;
    width: 100%;
}
@media (max-width:1024px){
    .new-universe{
        padding-top: 38px;
    }
    .new-universe .parent .main-content{
        padding-left: 15px;
    }
    .new-universe .parent .main-content h4{
        font-size: 24px;
    }
    .new-universe .parent .main-content .para{
        font-size: 16px;
    }
    .new-universe .parent{
        padding-bottom: 300px;
    }
    .new-universe .bg-section{
        height: 100%;
    }
    .new-universe .top-center-shadow{
        display: none;
    }
    .top-center-shadow-mobile{
        display: block !important;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        pointer-events: none;
    }
}

@media (max-width:600px){
.new-universe .parent .main-content .white-btn {

    white-space: nowrap;
    padding: 15px !important;
}
}