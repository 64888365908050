.health-section{
    .cadrdddds{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
h6{
color: #2DD98F;
padding-bottom: 20px;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 19.2px */
}
h2{
    border: 1px solid #1B1B1B;
    width: 100%;
}
    .inner_pro{
        h1{
            color: #FFF;
padding-top: 112px;
padding-bottom: 152px;
font-size: 62px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 62px */
text-transform: uppercase;
        }
        
        .planets{
            position: absolute;
            right: 0px;
            top: 72px;
        }


    }
    .innnernew{
        h5{
            color: #000;
margin-top: 95px;
            padding: 20px 30px;
            background: #2DD98F;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 24px */
        }
    }
    .outttter{
        border: 1px solid #1B1B1B;
margin-top: 40px;
margin-bottom: 95px;
.cardpart{
    padding: 25px 30px;
    border-bottom: 1px solid #1B1B1B;
    p{
        color: #FFF;
padding-bottom: 20px;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 21.6px */
    }
    .innnner_div{
        display: flex;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
    }
    h1{
        color: #717171;
white-space: nowrap;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 19.2px */
    }
}
    }
}
@media (max-width: 1024px){
    .health-section .inner_pro h1 {
        color: #FFF;
        padding-top: 73px;
        padding-bottom: 152px;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
    }
    .health-section .outttter .cardpart .innnner_div {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
  
    }
    .health-section .outttter .cardpart h1 {
        color: #717171;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        white-space: nowrap;
    }
}