.blog-section {
    padding-top: 70px;
    padding-bottom: 137px;

    .featured-blog {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 49px;
        padding-bottom: 49px;

        .left-side {
            max-width: 517px;
            width: 100%;

            .main-heading {
                h4 {
                    color: #FFF;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    text-transform: uppercase;
                    margin-bottom: 19px;
                }

                p {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 110%;
                    margin-bottom: 36px;

                    span {
                        color: #2DD98F;
                    }
                }
            }

            .main-img {
                width: 100%;
                height: 341px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .right-side {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 15px;
            flex-direction: column;
            flex: 1;

            .single-blog {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid rgba(255, 255, 255, 0.07);
                background: #000;
                padding: 20px;
                transition: 0.3s linear;
                gap: 20px;
                cursor: pointer;

                &:hover {
                    border: 1px solid #2DD98F;
                    background: rgba(0, 0, 0, 0.80);
                }

                h6 {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                    margin-bottom: 20px;
                }

                p {
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    text-transform: uppercase;
                }

                .right-img {
                    width: 179px;
                    height: 112px;
                    overflow: hidden;

                }
            }
        }
    }

    .latest-article {
        padding-top: 70px;

        .main-heading {
            margin-bottom: 62px;

            h4 {
                color: #FFF;
                font-size: 45px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                text-transform: uppercase;
            }
        }

        .bottom-cards {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 62px 20px;

            .single-card {
                border: 1px solid rgba(255, 255, 255, 0.07);
                background: rgba(0, 0, 0, 0.80);
                transition: 0.3s linear;
                cursor: pointer;

                .main-img {
                    width: 100%;
                    height: 205px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .bottom-text {
                    padding: 20px;

                    h5 {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%;
                        margin-bottom: 15px;
                    }

                    .twice-text {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        p {
                            color: rgba(255, 255, 255, 0.50);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            text-transform: uppercase;
                        }

                        a {
                            color: #2DD98F;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;
                            text-transform: uppercase;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            transition: 0.3s linear;

                            svg {
                                path {
                                    transition: 0.3s linear;
                                }
                            }
                        }
                    }
                }

                &:hover {
                    border: 1px solid #2DD98F;
                    background: rgba(0, 0, 0, 0.80);
                    box-shadow: 0px 21px 30px -20px #2DD98F;

                    .bottom-text {
                        a {
                            color: #fff;

                            svg {
                                path {
                                    stroke: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.blog-detail {
    position: relative;
    z-index: 99;
    min-height: 100vh;
    padding-top: 450px;
    padding-bottom: 111px;

    .top-img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        pointer-events: none;
        width: 100%;
        height: 730px;
        overflow: hidden;

        .top-main-img {
            width: 100%;
            height: 100%;
            object-fit: cover;

        }

        .shadow-blogdetail {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
            z-index: 0;
        }
    }

    .inside-content {
        max-width: 752px;
        width: 100%;
        margin: 0 auto;

        .upper-content {
            p {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                span {
                    display: flex;
                    background: #2DD98F;
                    width: 8px;
                    height: 8px;
                }
            }

            h4 {
                margin: 15px 0;
                color: #FFF;
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                text-transform: uppercase;
            }

            .social-icons {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;

                a {
                    cursor: pointer;
                    border-radius: 100px;
                    background: rgba(255, 255, 255, 0.25);
                    width: 28px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .bottom-content{
            margin-top: 113px;
            a{
                color: #999999 !important;
                text-decoration: underline !important;
            }
            h1,h2,h3,h4,h5,h6{
                color: #FFF;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; 
                margin: 15px 0;
            }
            h5{
                font-size: 27px;
            }
            h6{
                font-size: 18px;
            }
            p{
                color: #999999;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; 
                margin: 15px 0;
                text-align: start !important;
                a{
                    color: #999999 !important;
                    text-decoration: underline !important;
                }
            }
            ul li{
                color: #999999;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; 
                margin: 15px 0;
                margin-left: 25px;
                list-style-type: disc;
                text-align: start !important;
                
                
            }

            ol li{
                color: #999999;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; 
                margin: 15px 0;
                margin-left: 25px;
                text-align: start !important;
            }
        }
    }
}


@media (max-width: 1024px){
    .blog-section .featured-blog{
        flex-direction: column;
    }
    .blog-section .latest-article .bottom-cards{
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .blog-section .featured-blog .left-side .main-heading h4{
        font-size: 28px;
    }
    .blog-section{
        padding-top: 50px;
    }
    .blog-section .featured-blog .left-side .main-img{
        height: 257px;
    }
    .blog-section .featured-blog .right-side .single-blog{
        gap: 8px;
        align-items: center;
    }
    .blog-section .featured-blog .right-side .single-blog .right-img{
        display: flex;
        align-items: center;
    }
    .blog-section .latest-article .main-heading h4{
        font-size: 27px;
    }
    .blog-detail .top-img{
        height: 358px;
    }
    .blog-detail{
        padding-top: 211px;
    }
    .blog-detail .inside-content .upper-content h4{
        font-size: 22px;
    }
    .blog-detail .inside-content .bottom-content h5{
        font-size: 22px;
    }
    .blog-detail .inside-content .bottom-content p{
        font-size: 14px;
    }
    .blog-detail .top-img .shadow-blogdetail{
        height: 248px;
    }
}