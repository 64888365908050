.main-footer {
    position: relative;
    z-index: 99;
    padding: 50px 0;

    .footer-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .left-side {
            .main-logo {
                margin-bottom: 25px;
            }

            .social-icons {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                margin-bottom: 70px;

                a {
                    border-radius: 100px;
                    background: rgba(255, 255, 255, 0.25);
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                       path{
                        transition: 0.3s linear;
                       }

                    }

                    &:hover {
                        svg {
                            path{
                                fill: #2DD98F;
                            }
                        }
                    }

                }
            }

            .copyright-text {
                color: rgba(255, 255, 255, 0.50);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }
        }

        .right-content {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 58px;

            .single-link {
                h6 {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    text-transform: uppercase;

                }

                a {
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    display: block;
                    margin-top: 20px;
                    transition: 0.8s linear;
                    &:hover{
                        color: #2DD98F;
                    }

                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .main-footer .parent {
        flex-direction: column;
    }

    .main-footer .footer-bg {
        height: 100%;
    }

    .main-footer .parent .left-side .copyright-text {
        display: none;
    }

    .main-footer .parent .right-content {
        gap: 30px;
    }

    .displayblockinmobile {
        display: flex !important;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.50);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-top: 50px;
    }

    .main-footer .parent .left-side .social-icons {
        margin-bottom: 50px;
    }

    .main-footer .parent .right-content .single-link {
        flex-grow: 1;
    }
}