.maincareer {
    background: url(../../../src/assets/resourcebg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding-top: 112px;
    padding-bottom: 180px;

    .mainhead {
        color: #FFF;
        font-size: 62px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        max-width: 291px;
        width: 100%;
        text-transform: uppercase;
    }
}

.job {
    background: url(../../../src/assets/background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;
    padding: 95px 0px;
    height: 1197px;

    .jobhead {
        color: #FFF;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        /* 38.5px */
        text-transform: uppercase;
        margin-bottom: 40px;


    }

    .parentjobbox {

        .innerjobbox {
            border: 1px solid rgba(255, 255, 255, 0.07) !important;
            background: rgba(0, 0, 0, 0.85) !important;
            padding: 40px;
            width: 100%;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0px !important;
            }

            &:hover {
                border: 1px solid #2DD98F !important;
                background: rgba(0, 0, 0, 0.85);

                .rightttt {
                    h6 {
                        color: #4DD388 !important;
                    }
                    svg{
                        path{
                            stroke: #2DD98F;
                        }
              
                    }
                }
            }

            .innerparenttext {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .leftyyyyy {
                    h3 {
                        color: #FFF;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%
                    }

                    p {
                        color: #BDBDBD;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        margin: 25px 0px;
                    }

                    .buttontwice {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;

                        button {
                            background: rgba(255, 255, 255, 0.06);
                            padding: 8px;
                            max-width: 130px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 5px;
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            border: none;
                            white-space: nowrap;
                        }
                    }
                }

                .rightttt {
                    h6 {

                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 5px;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                    }
                }
            }


        }
    }
}

@media(max-width:991px) {
    .maincareer {
        object-position: right;
        /* padding-top: 79px; */
        /* padding-bottom: 0px; */
        object-fit: cover;
        background-position: right;
        min-height: 200px;
    }

    .maincareer .mainhead {
        font-size: 26px;
    }
}

@media(max-width:660px) {
    .maincareer {
        object-position: right;
        /* padding-top: 79px; */
        /* padding-bottom: 0px; */
        object-fit: cover;
        background-position: right;
        min-height: 200px;
    }

    .maincareer .mainhead {
        font-size: 26px;
    }
    .job .jobhead{
        font-size: 20px;
    }
    .job .parentjobbox .innerjobbox{
        padding: 15px;
    }
    .job .parentjobbox .innerjobbox .innerparenttext .leftyyyyy h3{
        font-size: 16px;
    }
    .job .parentjobbox .innerjobbox .innerparenttext .leftyyyyy p{
        font-size: 14px;
    }
    .job .parentjobbox .innerjobbox .innerparenttext .rightttt h6{
        font-size: 12px;
        white-space: nowrap;
    }
}

