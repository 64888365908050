.features-section {
    padding-top: 80px;
    padding-bottom: 62px;
    position: relative;
    z-index: 98;
    .features-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
        z-index: -1;
    }

    .main-heading {
        margin-bottom: 52px;

        p {
            color: rgba(255, 255, 255, 0.50);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            margin-bottom: 14px;
        }

        h4 {
            color: #FFF;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 566px;
        gap: 20px;
        position: relative;
        overflow: hidden;

        .left-cards {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            flex-direction: column;
            gap: 16px;
            height: 100%;

            .single-card {
                width: 100%;
                height: 100%;
                border: 1px solid rgba(255, 255, 255, 0.07);
                background: rgba(0, 0, 0, 0.80);
                padding: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 40px;
                position: relative;
                z-index: 9;
                
                .card-bg-horizontal{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: -1;
                    pointer-events: none;
                }

                .inside-left {
                    max-width: 351px;
                    width: 100%;
                    h5 {
                        color: #FFF;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 100%;
                        text-transform: uppercase;
                        
                    }

                    p {
                        color: #BDBDBD;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        margin-top: 20px;
                    }

                    .price-div {
                        margin-top: 40px;

                        h6 {
                            color: #FFF;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: 100%;
                            text-transform: uppercase;
                            // margin-bottom: 10px;
                        }

                        p {
                            color: rgba(255, 255, 255, 0.50);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            text-transform: uppercase;
                            margin-top: 10px;
                        }
                    }
                }
                .img-wrapper{
                    flex-shrink: 0;
                }
            }
        }
        .right-card{
            max-width: 396px;
            width: 100%;
            height: 100%;
            .single-card {
                height: 100%;
                border: 1px solid rgba(255, 255, 255, 0.07);
                background: rgba(0, 0, 0, 0.80);
                padding: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                gap: 40px;
                position: relative;
                z-index: 9;
                .card-bg-vertical{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    pointer-events: none;
                }

                .bottom-text {
                    width: 100%;
                    h5 {
                        color: #FFF;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 100%;
                        text-transform: uppercase;
                        // margin-bottom: 20px;
                    }

                    p {
                        color: #BDBDBD;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        margin-top: 20px;
                    }

                    .price-div {
                        margin-top: 40px;

                        h6 {
                            color: #FFF;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: 100%;
                            text-transform: uppercase;
                            // margin-bottom: 10px;
                        }

                        p {
                            color: rgba(255, 255, 255, 0.50);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            text-transform: uppercase;
                            margin-top: 10px;
                        }
                    }
                }
                .img-wrapper{
                    flex-shrink: 0;
                }
            }
        }
    }
}

@media (max-width:1024px){
    .features-section .main-heading h4{
        font-size: 28px;
    }
    .features-section .parent{
        flex-direction: column;
        height: auto;
    }
    .features-section .parent .left-cards .single-card .card-bg-horizontal{
        height: 100%;
    }
    .features-section .features-bg{
        height: 100%;
    }
    .features-section .parent .left-cards .single-card{
        gap: 7px;
    }
    .features-section .parent .left-cards .single-card .inside-left h5{
        font-size: 22px;
    }
    .features-section .parent .left-cards .single-card .inside-left p{
        font-size: 14px;
    }
    .features-section .parent .left-cards .single-card .img-wrapper{
        width: 100px;
        height: 100px;
    }
    .features-section .parent .left-cards .single-card .inside-left .price-div h6{
        font-size: 20px;
    }
    .features-section .parent .right-card .single-card{
        flex-direction: row-reverse;
        gap: 7px;
    }
    .features-section .parent .right-card .single-card .img-wrapper{
        width: 82px;
        height: 82px;
    }
    .features-section .parent .right-card .single-card .bottom-text h5{
        font-size: 22px;
    }
    .features-section .parent .right-card .single-card .bottom-text p{
        font-size: 14px;
    }
    .features-section .parent .right-card .single-card .bottom-text .price-div h6{
        font-size: 20px;
    }
    .features-section .parent .right-card .single-card .bottom-text{
        max-width: 215px;
    }
}